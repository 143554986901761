// React
import React, { useState, useEffect } from "react";

// Router
import { useHistory, useLocation, useParams } from "react-router-dom";

// Component
import {
  CRUDLayout,
  DataStatus,
  THead,
  TBody,
  TdFixed,
  Tr,
  Th,
  Td,
  ActionButton,
  Select,
  TextArea,
  Alert,
} from "../../../components";
import { Row, Col, Card, Button } from "react-bootstrap";

// API
import { KualifikasiVendorApi } from "../../../api";

// Icon
import { IoCheckmarkSharp, IoArrowBackOutline } from "react-icons/io5";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

const DataKualifikasiVendor = ({ setNavbarTitle }) => {
  let history = useHistory();
  const location = useLocation();
  let { id } = useParams();

  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(false);

  // Data View
  const [data, setData] = useState({
    jenis_vendor: [],
    dokumen_utama: [],
    dokumen_lainnya: [],
    kontak: {},
    jumlah_item: [],
    vendor_item_subcon: [],
    vendor_item_upah: [],
    alamat: {},
  });

  // alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  let createAlert = location?.state?.variant;

  const getData = () => {
    setIsLoading(true);
    setShowAlert(false);

    KualifikasiVendorApi.single(id)
      .then((res) => setData(res.data.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle("Data Kualifikasi Vendor");

    if (createAlert !== undefined) {
      setAlertConfig({ variant: location?.state?.variant, text: location?.state?.text });
      setShowAlert(true);
    } else {
      return getData();
    }

    // jalankan function request data ke server
    getData();

    // menangani pembersihan saat fetching data
    return () => {
      history.replace((createAlert = undefined));
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  const ShowData = ({ title, text }) => (
    <div className="d-flex flex-row">
      <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
        {title}
      </div>
      <span>:</span>
      <div
        className="text-dark"
        style={{
          paddingLeft: 5,
        }}
      >
        {text ? text : "-"}
      </div>
    </div>
  );

  const formInitialValues = {
    id_vendor_prakualifikasi: id,
    tgl_kualifikasi: new Date().toISOString().slice(0, 10),
    status_kualifikasi: "",
    catatan_kualifikasi: "",
    id_vendor: data.id_vendor,
  };

  const formValidationSchema = Yup.object().shape({
    status_kualifikasi: Yup.string().required("Status Kualifikasi wajib diisi"),
  });

  const formSubmitHandler = (values) => {
    KualifikasiVendorApi.update(values)
      .then(() => {
        history.push("/master/kualifikasi-vendor", {
          variant: "primary",
          text: "Ubah Kualifikasi Vendor berhasil",
          status: true,
        });
      })
      .catch((err) =>
        setAlertConfig({
          variant: "danger",
          text: `Ubah Kualifikasi Vendor gagal! (${err})`,
        })
      );
  };

  return (
    <>
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h5>DETAIL VENDOR</h5>

            <Button
              variant="outline-secondary"
              size="sm"
              className="mx-1 mb-1"
              onClick={() => history.goBack()}
            >
              <IoArrowBackOutline size={20} />
              <span className="ml-2">Kembali Ke Tabel</span>
            </Button>
          </div>

          <Alert
            show={showAlert}
            showCloseButton={true}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setShowAlert(false)}
          />

          <Card>
            <Card.Body>
              <Row>
                <Col lg="6">
                  <div className="mb-2">
                    <ShowData
                      title="Tanggal Prakualifikasi"
                      text={data.tgl_vendor_prakualifikasi}
                    />

                    <ShowData title="No Prakualifikasi" text={data.no_vendor_prakualifikasi} />

                    <ShowData
                      title="Status Hukum Vendor"
                      text={data.status_hukum ? "Berbadan Hukum" : "Tidak Berbadan Hukum"}
                    />
                  </div>

                  <div className="mb-2">
                    <ShowData title="Kode Vendor" text={data.kode_vendor} />

                    <ShowData title="Nama Vendor" text={data.nama_vendor} />

                    <ShowData
                      title="Jenis Vendor"
                      text={data.jenis_vendor.map((val) => val.jenis_vendor).join(", ")}
                    />

                    <ShowData title="Pimpinan Perusahaan" text={data.nama_pimpinan_perusahaan} />

                    <ShowData title="Jumlah Karyawan" text={data.jumlah_karyawan} />
                  </div>
                </Col>

                <Col lg="6">
                  <div className="mb-2">
                    <ShowData title="Contact Person" text={data.kontak.contact_person} />

                    <ShowData title="Telepon" text={data.kontak.telepon} />

                    <ShowData title="Email" text={data.kontak.email} />

                    <ShowData title="Website" text={data.kontak.website} />

                    <ShowData title="Media Sosial" text={data.kontak.media_sosial} />
                  </div>

                  <div className="mb-2">
                    <ShowData title="Alamat Vendor" text={data.alamat.alamat_vendor} />

                    <ShowData title="Desa" text={data.alamat.nama_desa} />

                    <ShowData title="Kecamatan" text={data.alamat.nama_kecamatan} />

                    <ShowData title="Kabupaten" text={data.alamat.nama_kabupaten} />

                    <ShowData title="Provinsi" text={data.alamat.provinsi} />

                    <ShowData title="Kode Pos" text={data.alamat.kode_pos} />
                  </div>
                </Col>
              </Row>

              {data.jenis_vendor.map((value) => {
                if (value.jenis_vendor == "Mandor") {
                  return (
                    <>
                      <small>List Item Upah Yang Disediakan Vendor Ini Sebagai Mandor</small>
                      <CRUDLayout.Table>
                        <THead>
                          <Tr>
                            <Th>No</Th>
                            <Th>Kode Item Bahan</Th>
                            <Th>Nama Item Bahan</Th>
                            <Th>Satuan Pakai</Th>
                          </Tr>
                        </THead>

                        <TBody>
                          {data.vendor_item_upah.length > 0 ? (
                            data.vendor_item_upah.map((val, index) => (
                              <Tr key={index}>
                                <Td>
                                  <div className="text-center">{index + 1}</div>
                                </Td>
                                <Td>
                                  <div className="text-center">{val.kode_item}</div>
                                </Td>
                                <Td>{val.nama_item}</Td>
                                <Td>{val.nama_satuan}</Td>
                              </Tr>
                            ))
                          ) : (
                            <Tr>
                              <td colSpan="4" className="my-2 font-weight-bold text-center">
                                Tidak Ada Data
                              </td>
                            </Tr>
                          )}
                        </TBody>
                      </CRUDLayout.Table>
                    </>
                  );
                } else if (value.jenis_vendor == "Subcon") {
                  return (
                    <>
                      <small>List Item Subcon Yang Disediakan Vendor Ini Sebagai Subcon</small>
                      <CRUDLayout.Table>
                        <THead>
                          <Tr>
                            <Th>No</Th>
                            <Th>Kode Item Subcon</Th>
                            <Th>Nama Item Subcon</Th>
                            <Th>Kelompok Subcon</Th>
                            <Th>Satuan Pakai</Th>
                          </Tr>
                        </THead>

                        <TBody>
                          {data.vendor_item_subcon.length > 0 ? (
                            data.vendor_item_subcon.map((val, index) => (
                              <Tr key={index}>
                                <Td>
                                  <div className="text-center">{index + 1}</div>
                                </Td>
                                <Td>
                                  <div className="text-center">{val.kode_item}</div>
                                </Td>
                                <Td>{val.nama_item}</Td>
                                <Td>{val.nama_kelompok}</Td>
                                <Td>{val.nama_satuan}</Td>
                              </Tr>
                            ))
                          ) : (
                            <Tr>
                              <td colSpan="5" className="my-2 font-weight-bold text-center">
                                Tidak Ada Data
                              </td>
                            </Tr>
                          )}
                        </TBody>
                      </CRUDLayout.Table>
                    </>
                  );
                } else if (value.jenis_vendor == "Supplier") {
                  return (
                    <>
                      <small>List Item Bahan Yang Disediakan Vendor Ini Sebagai Supplier</small>
                      <CRUDLayout.Table>
                        <THead>
                          <Tr>
                            <Th>No</Th>
                            <Th>Kode Item Bahan</Th>
                            <Th>Nama Item Bahan</Th>
                            <Th>Satuan Pakai</Th>
                            <Th>Jumlah</Th>
                          </Tr>
                        </THead>

                        <TBody>
                          {data.jumlah_item.length > 0 ? (
                            data.jumlah_item.map((val, index) => (
                              <Tr key={index}>
                                <Td>
                                  <div className="text-center">{index + 1}</div>
                                </Td>
                                <Td>
                                  <div className="text-center">{val.kode_item}</div>
                                </Td>
                                <Td>{val.nama_item}</Td>
                                <Td>{val.nama_satuan}</Td>
                                <Td>{val.jumlah_item}</Td>
                              </Tr>
                            ))
                          ) : (
                            <Tr>
                              <td colSpan="6" className="my-2 font-weight-bold text-center">
                                Tidak Ada Data
                              </td>
                            </Tr>
                          )}
                        </TBody>
                      </CRUDLayout.Table>
                    </>
                  );
                }
              })}

              <div className="mb-2">
                <strong>Lama Vendor Menyediakan Item : </strong>
                {data.lama_menyediakan_item == "1"
                  ? "Kurang Dari 1 (Satu) Tahun"
                  : data.lama_menyediakan_item == "2"
                  ? "Antara 1 (Satu) Sampai 3 (Tiga) Tahun"
                  : "Diatas 3 (Tiga) Tahun"}
              </div>

              <small>Kelengkapan Dokumen</small>
              <CRUDLayout.Table>
                <THead>
                  <Tr>
                    <Th>No</Th>
                    <Th>Uraian</Th>
                    <Th>Ada</Th>
                    <Th>Tidak Ada</Th>
                  </Tr>
                </THead>

                <TBody>
                  {data.dokumen_utama.map((val, index) => {
                    return (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <Td>{val.nama_dokumen}</Td>
                        <TdFixed>{val.ada_atau_tidak ? <IoCheckmarkSharp /> : <div />}</TdFixed>
                        <TdFixed>{!val.ada_atau_tidak ? <IoCheckmarkSharp /> : <div />}</TdFixed>
                      </Tr>
                    );
                  })}
                </TBody>
              </CRUDLayout.Table>

              <small>Dokumen Lainnya</small>
              <CRUDLayout.Table>
                <THead>
                  <Tr>
                    <Th>No</Th>
                    <Th>Uraian</Th>
                  </Tr>
                </THead>

                <TBody>
                  {data.dokumen_lainnya.length > 0 ? (
                    data.dokumen_lainnya.map((val, index) => {
                      return (
                        <Tr key={index}>
                          <TdFixed>{index + 1}</TdFixed>
                          <Td>{val.nama_dokumen}</Td>
                        </Tr>
                      );
                    })
                  ) : (
                    <td colSpan="2" className="my-2 font-weight-bold text-center">
                      Tidak Ada Data
                    </td>
                  )}
                </TBody>
              </CRUDLayout.Table>

              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}
              >
                {({ errors, touched, isSubmitting, handleChange, handleSubmit }) => (
                  <form className="py-2" onSubmit={handleSubmit}>
                    <small>Kesimpulan Kualifikasi</small>
                    <div className="d-flex align-items-center">
                      <div className="d-inline mr-2 mb-2">Status Kualifikasi</div>
                      <div className="d-inline">
                        <Select
                          name="status_kualifikasi"
                          onChange={handleChange}
                          error={errors.status_kualifikasi && touched.status_kualifikasi && true}
                          errorText={errors.status_kualifikasi}
                        >
                          <option value="" hidden>
                            Pilih Status
                          </option>
                          <option value="1">Lolos</option>
                          <option value="2">Tidak Lolos</option>
                        </Select>
                      </div>
                    </div>

                    <TextArea
                      label="Catatan"
                      type="text"
                      name="catatan_kualifikasi"
                      rows={4}
                      onChange={handleChange}
                    />

                    <div className="d-flex justify-content-end pt-3">
                      <ActionButton
                        type="submit"
                        variant="primary"
                        text="Simpan"
                        loading={isSubmitting}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default DataKualifikasiVendor;
