import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  CreateButton,
  ReadButton,
  UpdateButton,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  FilterButton
} from "../../../../components";
import { TableNumber, DateConvert } from "../../../../utilities";
import { PurchaseOrderApi } from "../../../../api";
import { Row, Col } from "react-bootstrap";
import ModalFilter from "./ModalFilter";

const PurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPurchaseOrder, setDataPurchaseOrder] = useState([]);

  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.po?.filter?.active,
      tgl_seleksi_vendor_mulai: location?.state?.po?.filter?.tgl_seleksi_vendor_mulai,
      tgl_seleksi_vendor_selesai: location?.state?.po?.filter?.tgl_seleksi_vendor_selesai,
      tgl_purchase_order_mulai: location?.state?.po?.filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai: location?.state?.po?.filter?.tgl_purchase_order_selesai,
      tgl_pengiriman_mulai: location?.state?.po?.filter?.tgl_pengiriman_mulai,
      tgl_pengiriman_selesai: location?.state?.po?.filter?.tgl_pengiriman_selesai,
      vendor: location?.state?.po?.filter?.vendor,
      nama_item: location?.state?.po?.filter?.nama_item,
    },
    pagination: {
      page: location?.state?.po?.filter?.page ?? "1",
      dataLength: location?.state?.po?.filter?.dataLength ?? "10",
      totalPage: location?.state?.po?.filter?.totalPage ?? "1",
      dataCount: location?.state?.po?.filter?.dataCount ?? "0",
    }
  })

  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
    key: "",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      // page: dataFilter?.pagination?.page,
      // per_page: dataFilter?.pagination?.dataLength,
      // tgl_seleksi_vendor_mulai: location?.state?.sv?.filter?.tgl_seleksi_vendor_mulai,
      // tgl_seleksi_vendor_selesai: location?.state?.sv?.filter?.tgl_seleksi_vendor_selesai,
      // tgl_purchase_order_mulai: location?.state?.sv?.filter?.tgl_purchase_order_mulai,
      // tgl_purchase_order_selesai: location?.state?.sv?.filter?.tgl_purchase_order_selesai,
      // tgl_pengiriman_mulai: location?.state?.sv?.filter?.tgl_pengiriman_mulai,
      // tgl_pengiriman_selesai: location?.state?.sv?.filter?.tgl_pengiriman_selesai,
      // vendor: location?.state?.sv?.filter?.vendor,
      // nama_item: location?.state?.sv?.filter?.nama_item,
    })
      .then(({ data }) => {
        setDataPurchaseOrder(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
        // setDataFilter({
        //   ...dataFilter,
        //   pagination: {
        //     ...dataFilter.pagination,
        //     dataCount: data?.data?.data_count,
        //     totalPage: data?.data?.total_page,
        //   }
        // })
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle("Purchase Order");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle, 
    searchConfig.key, 
    paginationConfig.page, 
    paginationConfig.dataLength,
    // dataFilter?.pagination?.page, 
    // dataFilter?.pagination?.dataLength, 
    // dataFilter?.filter?.active,
    // dataFilter?.filter?.tgl_seleksi_vendor_mulai,
    // dataFilter?.filter?.tgl_seleksi_vendor_selesai,
    // dataFilter?.filter?.tgl_purchase_order_mulai,
    // dataFilter?.filter?.tgl_purchase_order_selesai,
    // dataFilter?.filter?.tgl_pengiriman_mulai,
    // dataFilter?.filter?.tgl_pengiriman_selesai,
    // dataFilter?.filter?.vendor,
    // dataFilter?.filter?.nama_item,
  ]);

  const PageContent = () => {
    const generateStatusAPP = (value) => {
      if (value.toUpperCase() === "APP") {
        return "APPROVED";
      } else if (value.toUpperCase() === "REV") {
        return "REVISI";
      } else if (value.toUpperCase() === "REJ") {
        return "REJECT";
      } else if (value.toUpperCase() === "VER") {
        return "VERIFIED";
      }

      return "PENDING";
    };
    const generateStatusPR = (value) => {
      if (value.toUpperCase() === "OPEN") {
        return <b className="text-success">OPEN</b>;
      }

      return <b className="text-danger">CLOSE</b>;
    };
    const ItemCollapse = ({ data }) => {
      const [isReadMore, setIsReadMore] = useState(false)

      return (
        <>
          {data?.length < 1 
          ? <>
              <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
                {data?.map((brg, index) => {
                  if (isReadMore) {
                    return <li key={index} index={index}>{`${brg.nama_item} (${parseFloat(brg.qty ?? 0)} ${brg.nama_satuan ?? ''})`}</li>
                  } else {
                    return index < 2 && <li key={index} index={index}>{`${brg.nama_item} (${parseFloat(brg.qty ?? 0)} ${brg.nama_satuan ?? ''})`}</li>
                  }
                })}
              </ul>
              {data?.length > 2 &&
                <div
                  className="text-primary"
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => setIsReadMore((prev) => !prev)} >
                  {isReadMore ? "Sembunyikan" : "Selengkapnya..."}
                </div>
              }
            </>
          : "-"}
        </>
      )
    }

    const DataTable = () => (
      <>
        <div className="mt-3" style={{ fontSize: "14px" }}><b>List Data Purchase Order</b></div>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Purchase Order</ThFixed>
              <ThFixed>Informasi Seleksi Vendor</ThFixed>
              <ThFixed>Tgl. Pengiriman</ThFixed>
              <Th width={150}>Vendor</Th>
              <Th>Nama Item</Th>
            </Tr>
          </THead>
          <TBody>
            {dataPurchaseOrder.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</TdFixed>
                {/* <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed> */}
                <TdFixed>
                  <div className="d-flex justify-content-start align-items-center">
                    <ReadButton 
                      onClick={() => history.push("/transaksi/purchase-order/detail/" + val.id_purchase_order, { ...location?.state, po: dataFilter })}
                    />
                  </div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_purchase_order ? DateConvert(new Date(val.tgl_purchase_order)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_purchase_order ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_seleksi_vendor ? DateConvert(new Date(val.tgl_seleksi_vendor)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_seleksi_vendor ?? "-"}</div>
                </TdFixed>
                <Td>{val.tgl_pengiriman ? DateConvert(new Date(val.tgl_pengiriman)).defaultDMY : "-"}</Td>
                <Td><div style={{ width: "200px" }}>{val.nama_vendor}</div></Td>
                <Td>{val?.nama_item ?? "-"}</Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex">
            <InputSearch
              onChange={(e) => {
                const key = e.target.value;
                setSearchConfig({
                  ...searchConfig,
                  key: e.target.value,
                });
                setAlertConfig({
                  show: key ? true : false,
                  variant: "primary",
                  text: "Hasil dari pencarian: " + key,
                });
              }}
            />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalConfig({ ...modalConfig, show: true, type: "filter" })}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => history.push("/transaksi/purchase-order/vendor-terseleksi", { ...location?.state, po: dataFilter })}
            // onClick={() => history.push("/transaksi/purchase-order/tambah")}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <div className="mt-2">
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => setAlertConfig({ ...alertConfig, show: false })}
        />
      </div>

      {/* content */}
      {isPageLoading 
        ? (<DataStatus loading={true} text="Memuat data . . ." />) 
        : dataPurchaseOrder 
          ? (dataPurchaseOrder.length > 0 
            ? (<PageContent />) 
            : (<DataStatus text="Tidak ada data" />)) 
          : (<DataStatus text="Data gagal dimuat" />)
      }
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilter
          // dropdown={dropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default PurchaseOrder;


// {(val.status_approval === "PEN" || val.status_approval === "REV") && (
//   <UpdateButton
//     onClick={() =>
//       history.push("/transaksi/purchase-order/ubah/" + val.id_purchase_order)
//     }
//   />
// )}

// {/* <Pagination
//     dataLength={dataFilter?.pagination?.dataLength}
//     dataNumber={
//       dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
//     }
//     dataPage={
//       dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
//         ? dataFilter?.pagination?.dataCount
//         : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
//     }
//     dataCount={dataFilter?.pagination?.dataCount}
//     currentPage={dataFilter?.pagination?.page}
//     totalPage={dataFilter?.pagination?.totalPage}
//     onPaginationChange={({ selected }) =>
//       setDataFilter({
//         ...dataFilter,
//         pagination: {
//           ...dataFilter.pagination,
//           page: selected + 1,
//         }
//       })
//     }
//     onDataLengthChange={(e) =>
//       setDataFilter({
//         ...dataFilter,
//         pagination: {
//           ...dataFilter.pagination,
//           page: 1,
//           dataLength: e.target.value,
//         }
//       })
//     }
//   /> */}