import React, { useState } from "react"
import { Table, Th, ThFixed, Td, DataStatus, ReadButton } from "components"
import { DateConvert, RupiahConvert } from "utilities"
import { ModalDetail } from ".."

const TableHistory = ({ data }) => {
  const [modalConfig, setModalConfig] = useState({ show: false, data: {} })

  return (
    <React.Fragment>
      <small className="h6 font-weight-bold">History Transfer Upah Produksi</small>
      <div className="table-responsive">
        <Table>
          <thead className="text-center bg-light">
            <tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Informasi Transfer Upah Produksi</ThFixed>
              <Th>Item Upah</Th>
              <Th>Vendor</Th>
              <Th width={100}>Qty. Transfer</Th>
              <Th width={150}>Harga Satuan</Th>
              <Th width={150}>Total Harga</Th>
              <ThFixed>Aksi</ThFixed>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 && data?.map((val, index) => (
              <tr key={index}>
                <Td textCenter width={20}> {index + 1} </Td>
                <Td>
                  <div>{val.tgl_transfer_produksi_upah ? DateConvert(new Date(val.tgl_transfer_produksi_upah)).defaultDMY : "-"} </div>
                  <div>{val.no_transfer_produksi_upah ?? "-"}</div>
                </Td>
                <Td>{val.nama_item_buaso} </Td>
                <Td>{val.nama_vendor} </Td>
                <Td textRight>
                  {parseFloat(val.qty_transfer || 0)}{" "}
                  {val?.nama_satuan ?? ''}
                </Td>
                <Td textRight>{val?.harga_satuan_upah ? RupiahConvert(String(parseInt(val.harga_satuan_upah || 0))).detail : '-'} </Td>
                <Td textRight>{val?.harga_satuan_upah ? RupiahConvert(String(parseInt(val.harga_satuan_upah || 0) * parseFloat(val.qty_transfer || 0))).detail : '-'} </Td>
                <Td>
                  <ReadButton size="sm" className="btn btn-block" onClick={() => setModalConfig({ show: true, data: val })} />
                </Td>
              </tr>
            ))}

            {/* Handle tidak ada data */}
            {data?.length === 0 && (
              <tr>
                <Td colSpan={7} textCenter>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Modal Detail Transfer Upah Produksi */}
      {modalConfig.show && (<ModalDetail
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
      />)}
    </React.Fragment>
  )
}

export default TableHistory
