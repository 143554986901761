import { useState, useContext, useEffect, cloneElement, Fragment } from "react";
import { NavLink } from "react-router-dom";
import {
  Card,
  Accordion,
  useAccordionToggle,
  AccordionContext,
  Nav,
  Badge,
  Dropdown,
  Button
} from "react-bootstrap";
import {
  IoChevronForwardOutline,
  IoClose,
  IoGitCommitOutline,
  IoHomeOutline,
  IoMenuOutline,
  IoRemoveOutline,
} from "react-icons/io5";
import { ActionButton } from "components";
import { AuthContext, AuthMethod, NotifContext } from "utilities";
import Config from "config";

const Layout = ({ children }) => {
  const { REACT_APP_ENV } = process.env
  const [navbarTitle, setNavbarTitle] = useState("");
  const [isSidebarCollapse, setIsSidebarCollapse] = useState(false);
  const [childrenKey, setChildrenKey] = useState("");
  const { state, dispatch } = useContext(AuthContext);
  const notif = useContext(NotifContext);
  const { username, role } = state;
  const { LOGO, MENU, MODUL } = Config;

  // Check apakah link pada sidebar adalah dropdown
  const checkDropdown = (val) => {
    if (val?.menu && val.menu.length > 0) {
      return false;
    }

    return true;
  };

  // Check apakah ada notif pada sidebar
  const checkNotif = (hak) => {
    let totalNotif = 0;

    for (const props in notif) {
      if (hak.find((find) => find === props)) {
        totalNotif = totalNotif + notif[props];
      }
    }

    return totalNotif > 0 ? totalNotif : null;
  };

  useEffect(() => {}, []);

  // Tampilan link pada sidebar
  const SidebarLink = ({
    text,
    link,
    icon,
    exact,
    notifCount,
    childrenNumber,
    onClick,
    eventKey,
  }) => {
    return (
      <Nav.Link
        exact={exact}
        as={NavLink}
        to={link}
        className="nav-link sidebar-link d-flex justify-content-between align-items-start px-2 mb-1 rounded"
        activeClassName="active"
        style={{ fontSize: childrenNumber === 2 ? "12px" : childrenNumber === 3 ? "12px" : "14px" }}
        onClick={onClick}
        eventKey={eventKey}
      >
        <div
          className="d-flex "
          style={{
            paddingLeft: childrenNumber === 2 ? "15px" : childrenNumber === 3 ? "30px" : "",
          }}
        >
          <div className="mr-2">
            {icon ? (
              icon
            ) : childrenNumber === 2 ? (
              <IoGitCommitOutline />
            ) : childrenNumber === 3 ? (
              <IoRemoveOutline />
            ) : (
              <IoGitCommitOutline />
            )}
          </div>
          <b className="text-uppercase mt-1">{text}</b>
        </div>
        <div>
          <Badge pill variant="danger" style={{ marginRight: "22.5px" }}>
            {notifCount}
          </Badge>
        </div>
      </Nav.Link>
    );
  };

  // Tampilan link dropdown pada sidebar
  const SidebarLinkDropdown = ({ text, icon, notifCount, children, childrenNumber, eventKey }) => {
    const AccordionTitle = ({ eventKey, callback }) => {
      const currentEventKey = useContext(AccordionContext);
      const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
      const isCurrentEventKey = currentEventKey === eventKey;

      return (
        <Accordion.Toggle
          as={Nav.Link}
          eventKey={eventKey}
          className={`nav-link sidebar-link-dropdown d-flex justify-content-between align-items-start px-2 mb-1 rounded ${
            isCurrentEventKey ? "active border" : ""
          }`}
          onClick={decoratedOnClick}
          style={{
            fontSize: childrenNumber === 2 ? "12px" : childrenNumber === 3 ? "12px" : "14px",
          }}
        >
          <div
            className="d-flex "
            style={{
              paddingLeft: childrenNumber === 2 ? "15px" : childrenNumber === 3 ? "30px" : "",
            }}
          >
            <div className="mr-2">
              {icon ? (
                icon
              ) : childrenNumber === 2 ? (
                <IoGitCommitOutline />
              ) : childrenNumber === 3 ? (
                <IoRemoveOutline />
              ) : (
                <IoGitCommitOutline />
              )}
            </div>
            <b className="text-uppercase mt-1">{text}</b>
          </div>
          <div>
            <Badge pill variant="danger" className="mr-2">
              {notifCount}
            </Badge>
            <IoChevronForwardOutline
              className="mt-1"
              style={{
                transform: `rotate(${isCurrentEventKey ? "90deg" : "0"})`,
                transition: "all 0.3s ease-in-out",
              }}
            />
          </div>
        </Accordion.Toggle>
      );
    };

    return (
      <>
        {/* Header */}
        <AccordionTitle eventKey={eventKey} />

        {/* Body */}
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body className="p-0 shadow-none">{children}</Card.Body>
        </Accordion.Collapse>
      </>
    );
  };

  // Tampilan navbar pada content
  const Navbar = ({ navbarTitle, isSidebarCollapse, setIsSidebarCollapse }) => {
    return (
      <nav className="navbar-wrapper shadow-sm">
        <div className="d-flex align-items-center">
          <IoMenuOutline
            size={28}
            className="text-primary mx-3"
            style={{ cursor: "pointer" }}
            onClick={() => setIsSidebarCollapse(!isSidebarCollapse)}
          />
          <b className="text-uppercase" style={{ fontSize: 14 }}>
            {navbarTitle}
          </b>
        </div>
        <div className="account-info">
          <Dropdown>
            <Dropdown.Toggle variant="none" className="d-flex align-items-center text-dark">
              <div className="avatar mr-2 rounded-circle bg-success text-white">
                {username.charAt(0).toUpperCase()}
              </div>
              <span className="text-uppercase mr-1">{username}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mt-1">
              <Dropdown.Item
                onClick={async () => {
                  await dispatch({ type: AuthMethod.LOGOUT });
                }}
              >
                LOG OUT
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
    );
  };

  return (
    <div className="layout-wrapper">
      {/* Sidebar */}
      <aside className={`sidebar-wrapper ${isSidebarCollapse ? "collapsed" : ""}`} />
      <aside className={`sidebar-wrapper action shadow-sm ${isSidebarCollapse ? "collapsed" : ""}`}>
        <div className="sidebar-content">
          {/* Sidebar Head */}
          <div className="sidebar-head p-3 py-4 border-bottom">
            <div>
              <img src={LOGO} alt="logo" className="img-fluid" style={{ maxHeight: "120px" }} />
            </div>
            <div className="text-center text-uppercase mt-3">
              <b className="text-uppercase">MODUL {MODUL}</b>
              {REACT_APP_ENV.toUpperCase() === "DEVELOPMENT" && (
                <Button variant="outline-danger mt-1 px-4" size="sm">
                  DEV
                </Button>
              )}
              {/* <br /> */}
              {/* <b className="text-uppercase">{sessionStorage.getItem("nama_unit_produksi")}</b> */}
            </div>
            <IoClose
              className="close text-primary m-4"
              onClick={() => setIsSidebarCollapse(!isSidebarCollapse)}
            />
          </div>

          {/* Sidebar Body */}
          <div className="sidebar-body mx-2 py-2">
            <Accordion className="mb-1">
              {MENU.map(
                (parent, index) =>
                  parent.hak &&
                  parent.hak.some((hak) => role.includes(hak)) && (
                    <Fragment key={index}>
                      {checkDropdown(parent) ? (
                        <SidebarLink
                          exact={parent.exact}
                          text={parent.text}
                          link={parent.link}
                          icon={parent.icon}
                          eventKey={index + 1}
                          notifCount={checkNotif(parent.hak)}
                          onClick={() => setIsSidebarCollapse(false)}
                        />
                      ) : (
                        <SidebarLinkDropdown
                          text={parent.text}
                          icon={parent.icon}
                          eventKey={index + 1}
                          notifCount={checkNotif(parent.hak)}
                        >
                          <Accordion defaultActiveKey={childrenKey}>
                            {parent.menu &&
                              parent.menu.map(
                                (child1, indexChild1) =>
                                  child1.hak &&
                                  child1.hak.some((hak) => role.includes(hak)) && (
                                    <Fragment key={indexChild1}>
                                      {checkDropdown(child1) ? (
                                        <SidebarLink
                                          exact={child1.exact}
                                          text={child1.text}
                                          link={child1.link}
                                          icon={child1.icon}
                                          childrenNumber={2}
                                          eventKey={indexChild1 + 100}
                                          notifCount={checkNotif(child1.hak)}
                                          onClick={() => setIsSidebarCollapse(false)}
                                        />
                                      ) : (
                                        <SidebarLinkDropdown
                                          text={child1.text}
                                          icon={child1.icon}
                                          eventKey={indexChild1 + 100}
                                          childrenNumber={2}
                                          notifCount={checkNotif(child1.hak)}
                                        >
                                          {child1.menu &&
                                            child1.menu.map((child2, indexChild2) => (
                                              <Fragment key={indexChild2}>
                                                {child2.hak &&
                                                  child2.hak.some((hak) => role.includes(hak)) && (
                                                    <SidebarLink
                                                      exact={child2.exact}
                                                      text={child2.text}
                                                      link={child2.link}
                                                      icon={child2.icon}
                                                      eventKey={indexChild2 + 100}
                                                      childrenNumber={3}
                                                      notifCount={checkNotif(child2.hak)}
                                                      onClick={() => {
                                                        setChildrenKey(indexChild1 + 100);
                                                        setIsSidebarCollapse(false);
                                                      }}
                                                    />
                                                  )}
                                              </Fragment>
                                            ))}
                                        </SidebarLinkDropdown>
                                      )}
                                    </Fragment>
                                  )
                              )}
                          </Accordion>
                        </SidebarLinkDropdown>
                      )}
                    </Fragment>
                  )
              )}
            </Accordion>
          </div>
        </div>
      </aside>

      {/* Content Section */}
      <section className="content-wrapper">
        {/* Navbar */}
        <Navbar
          navbarTitle={navbarTitle}
          isSidebarCollapse={isSidebarCollapse}
          setIsSidebarCollapse={setIsSidebarCollapse}
        />

        {/* Content */}
        <div className="content px-3 responsive">{cloneElement(children, { setNavbarTitle })}</div>
      </section>

      {/* Backdrop (hanya tersedia pada breakpoint mobile) */}
      {isSidebarCollapse && (
        <div className="backdrop" onClick={() => setIsSidebarCollapse(!isSidebarCollapse)} />
      )}
    </div>
  );
};

export default Layout;
