import React, { useState, useRef, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import {
  Alert,
  ActionButton,
  BackButton,
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  Approval,
} from "../../../../components";
import { RupiahConvert, DateConvert } from "../../../../utilities";
import { PurchaseOrderApi } from "../../../../api";
import PrintPurchaseOrder from "./PrintPurchaseOrder";

const DetailPurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const componentRef = useRef();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataPurchaseOrder, setDataPurchaseOrder] = useState({});
  const [dataPODetail, setDataPODetail] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderApi.getSingle({ id_purchase_order: id })
      .then(({ data }) => {
        const dataPO = data.data;
        const dataDetail = [];

        setDataPurchaseOrder(dataPO);

        dataPO.po_detail &&
          dataPO.po_detail.map((val) => {
            const processed = {
              id_item_buaso: val.id_item_buaso,
              qty_order: val.qty_order,
              harga_kesepakatan: val.harga_kesepakatan,
              kode_item: val.kode_item,
              nama_item: val.nama_item,
              id_seleksi_vendor_detail: val.id_seleksi_vendor_detail[0],
              dimensi: val.dimensi,
              pj: val.pj,
              tb: val.tb,
              lb: val.lb,
              nama_satuan: val.nama_satuan,
            };
            dataDetail.push(processed);
          });

        setDataPODetail(dataDetail);
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const PageContent = () => {
    const dataPengaju = dataPurchaseOrder?.stakeholder ? dataPurchaseOrder.stakeholder : [];

    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td>
          <div className="pl-4 pr-2">: </div>
        </td>
        <td>{value}</td>
      </tr>
    );

    const InfoItems = ({ title, value }) => (
      <tr>
        <td width={125}>{title}</td>
        <td className="pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    let total = 0;
    let nilaiDiskon = 0;
    let setelahDiskon = 0;
    let nilaiPPN = 0;
    let setelahPPN = 0;

    dataPODetail.map((val, index) => {
      total = total + val.harga_kesepakatan * val.qty_order;
      nilaiDiskon = dataPurchaseOrder.diskon
        ? parseInt(dataPurchaseOrder.diskon) === 0
          ? 0
          : parseInt((dataPurchaseOrder.diskon / 100) * total)
        : 0;
      setelahDiskon = parseInt(total - nilaiDiskon);
      nilaiPPN = dataPurchaseOrder.ppn
        ? parseInt(dataPurchaseOrder.ppn) === 0
          ? 0
          : parseInt((dataPurchaseOrder.ppn / 100) * setelahDiskon)
        : 0;
      setelahPPN = parseInt(setelahDiskon + nilaiPPN);
    });

    const CatatanSection = () => (
      <div>
        <div className="mt-3 mb-3">Pesanan ini selambat-lambatnya telah sampai pada :</div>
        <Row>
          <Col>
            <table>
              <tbody>
                <InfoItems
                  title="Tanggal"
                  value={
                    dataPurchaseOrder.tgl_pengiriman
                      ? DateConvert(new Date(dataPurchaseOrder.tgl_pengiriman)).detail
                      : "-"
                  }
                />
                <InfoItems title="Pembayaran" value={dataPurchaseOrder.pembayaran} />
                <InfoItems title="Dikirim ke" value={dataPurchaseOrder.alamat_tujuan_pengiriman} />
              </tbody>
            </table>
          </Col>
          <Col>
            <div className="d-flex justify-content-end">
              <table>
                <tbody>
                  <InfoItems
                    title="Sub Total"
                    value={RupiahConvert(parseInt(total).toString()).detail}
                  />
                  <InfoItems
                    title={`Diskon ${dataPurchaseOrder.diskon} %`}
                    value={RupiahConvert(nilaiDiskon.toString()).detail}
                  />
                  <InfoItems
                    title="Pengenaan Pajak"
                    value={RupiahConvert(setelahDiskon.toString()).detail}
                  />
                  <InfoItems
                    title={`PPN ${dataPurchaseOrder.ppn} %`}
                    value={RupiahConvert(nilaiPPN.toString()).detail}
                  />
                  <InfoItems
                    title={<b>Total</b>}
                    value={RupiahConvert(setelahPPN.toString()).detail}
                  />
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    );

    // console.log(dataPurchaseOrder);

    const InfoSection = () => {
      return (
        <>
          <Row className="mb-3">
            <Col>
              <table>
                <tbody>
                  <InfoItem
                    title="No. PO"
                    value={
                      dataPurchaseOrder.no_purchase_order
                        ? dataPurchaseOrder.no_purchase_order
                        : "-"
                    }
                  />
                  <InfoItem
                    title="No. Seleksi Vendor"
                    value={
                      dataPurchaseOrder.no_seleksi_vendor
                        ? dataPurchaseOrder.no_seleksi_vendor
                        : "-"
                    }
                  />
                  <InfoItem
                    title="Tanggal"
                    value={
                      dataPurchaseOrder.tgl_purchase_order
                        ? DateConvert(new Date(dataPurchaseOrder.tgl_purchase_order)).detail
                        : "-"
                    }
                  />
                  <InfoItem
                    title="Tgl. Jatuh Tempo"
                    value={
                      dataPurchaseOrder.tgl_jatuh_tempo
                        ? DateConvert(new Date(dataPurchaseOrder.tgl_jatuh_tempo)).detail
                        : "-"
                    }
                  />
                  <InfoItem
                    title="Refrensi PR"
                    value={
                      dataPurchaseOrder.contact_person ? dataPurchaseOrder.contact_person : "-"
                    }
                  />
                </tbody>
              </table>
            </Col>
            <Col>
              <table>
                <tbody>
                  <InfoItem title="Kepada" />
                  <span>{dataPurchaseOrder.nama_vendor ? dataPurchaseOrder.nama_vendor : "-"}</span>
                  <br />
                  <span>
                    {dataPurchaseOrder.alamat_vendor ? dataPurchaseOrder.alamat_vendor : "-"}
                  </span>
                </tbody>
              </table>
            </Col>
          </Row>
        </>
      );
    };

    const ListItemPurchaseOrderSection = () => {
      let total = 0;
      let nilaiDiskon = 0;
      let setelahDiskon = 0;
      let nilaiPPN = 0;
      let setelahPPN = 0;

      const TableFooterItems = ({ text, input, value }) => (
        <Tr>
          <Td colSpan={6} textRight>
            <b>{text}</b>
          </Td>
          <Td textRight colSpan={2}>
            <div className="d-flex align-items-center justify-content-between text-nowrap">
              {input ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div style={{ width: "70px" }}>{input}</div>
                  <div className="pb-1 ml-2">
                    <b>%</b>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div className="text-right">
                <b>{value}</b>
              </div>
            </div>
          </Td>
        </Tr>
      );

      return (
        <>
          <div className="py-2 pl-1">
            <span>Kami tempatkan order pembelian dengan sbb :</span>
          </div>
          {dataPODetail ? (
            dataPODetail.length > 0 ? (
              <>
                <Table>
                  <THead>
                    <Tr>
                      <ThFixed>No.</ThFixed>
                      <ThFixed>Kode</ThFixed>
                      <Th>Nama Barang/Jasa</Th>
                      <Th>Qty</Th>
                      <Th>Satuan</Th>
                      <Th>Harga Satuan</Th>
                      <Th>Jumlah Harga</Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {dataPODetail.map((val, index) => {
                      total = total + val.harga_kesepakatan * val.qty_order;
                      nilaiDiskon = dataPurchaseOrder.diskon
                        ? parseInt(dataPurchaseOrder.diskon) === 0
                          ? 0
                          : parseInt((dataPurchaseOrder.diskon / 100) * total)
                        : 0;
                      setelahDiskon = parseInt(total - nilaiDiskon);
                      nilaiPPN = dataPurchaseOrder.ppn
                        ? parseInt(dataPurchaseOrder.ppn) === 0
                          ? 0
                          : parseInt((dataPurchaseOrder.ppn / 100) * setelahDiskon)
                        : 0;
                      setelahPPN = parseInt(setelahDiskon + nilaiPPN);

                      return (
                        <Tr key={index}>
                          <TdFixed>{index + 1}</TdFixed>
                          <TdFixed>{val.kode_item}</TdFixed>
                          <Td>
                            <div style={{ width: "200px" }}>
                              {/* {val.dimensi === "3"
                                ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                                : val.dimensi === "2"
                                ? `${val.nama_item} (${val.pj} X ${val.lb})`
                                : val.dimensi === "1"
                                ? `${val.nama_item} (${val.pj})`
                                : val.dimensi === "0"
                                ? val.nama_item
                                : ""} */}
                              {val.nama_item}
                            </div>
                          </Td>
                          <Td textRight>
                            {val.qty_order ? parseFloat(val.qty_order).toPrecision() : "-"}
                          </Td>
                          <Td>{val.nama_satuan ? val.nama_satuan : "-"}</Td>
                          <Td textRight>
                            {RupiahConvert(val.harga_kesepakatan.toString()).detail}
                          </Td>
                          <Td textRight>
                            {
                              RupiahConvert(
                                parseInt(val.qty_order * val.harga_kesepakatan).toString()
                              ).detail
                            }
                          </Td>
                        </Tr>
                      );
                    })}
                  </TBody>
                </Table>
                <CatatanSection />
              </>
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
        </>
      );
    };

    const FormCard = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Purchase Order</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {dataPengaju.map(
                (val, index) =>
                  index !== 0 &&
                  val.status_approval !== "PEN" && (
                    <Col sm>
                      <InfoItem
                        title1={
                          val.status_approval === "VER" || val.status_approval === "REV"
                            ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                            : val.status_approval === "APP"
                            ? "Pengesah"
                            : "Di Tolak Oleh"
                        }
                        value1={val.nama_karyawan ?? "-"}
                        title2="Catatan"
                        value2={val.catatan ?? "-"}
                      />
                    </Col>
                  )
              )}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    return (
      <div className="p-4">
        <InfoSection />
        <ListItemPurchaseOrderSection />
        <hr />
        {dataPengaju.length > 1 && <Approval data={dataPurchaseOrder} />}
      </div>
    );
  };

  useEffect(() => {
    setNavbarTitle("Detail Purchase Order");
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Detail Data Purchase Order</b>
        <div className="d-flex align-items-center">
          {!isPageLoading && (
            <ReactToPrint
              trigger={() => <ActionButton className="mb-1 mx-2" text="Cetak PO" />}
              content={() => componentRef.current}
            />
          )}
          <BackButton onClick={() => history.push("/transaksi/purchase-order", { ...location?.state })} />
        </div>
      </Card.Header>
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : isFetchingFailed ? (
        <DataStatus text="Data gagal dimuat, tidak dapat mengubah data!" />
      ) : (
        <>
          <PageContent />
          <div style={{ display: "none" }}>
            <PrintPurchaseOrder
              ref={componentRef}
              dataPurchaseOrder={dataPurchaseOrder}
              dataPODetail={dataPODetail}
            />
          </div>
        </>
      )}
    </Card>
  );
};

export default DetailPurchaseOrder;
