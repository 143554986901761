import Services from '../../services'

class HargaPerkiraanSendiriApi {
    // page(value, params) {
    //     return Services.get(`/harga_perkiraan_sendiri/page_${value}`, { params })
    // }
    page(params) {
        return Services.get("/harga_satuan_rata_rata/page", { params })
    }

    historyPage(params) {
        return Services.get("/harga_satuan_rata_rata/history", { params })
    }

    // single(value) {
    //     return Services.get(`/harga_perkiraan_sendiri/single?id_item=${value}`)
    // }

    update(data) {
        return Services.post("/harga_satuan_rata_rata", data)
    }
}

export default new HargaPerkiraanSendiriApi()