import {
  IoSpeedometerOutline,
  IoServerOutline,
  IoDocumentOutline,
  IoCashOutline,
} from "react-icons/io5"
import Dashboard from "../pages/Dashboard"

// MASTER PAGE
import {
  Vendor,
  TambahVendor,
  UbahVendor,
  DetailVendor,
  PrakualifikasiVendor,
  UbahPrakualifikasiVendor,
  TambahPrakualifikasiVendor,
  CalonVendor,
  KualifikasiVendor,
  Kualifikasi,
  DetailKualifikasiVendor,
  HargaPerkiraanSendiri,
  ItemUpah,
  ItemSubcont,
  KelompokUpahSubcont,
} from "../pages/Master"

// TRANSAKSI PAGE
import {
  SeleksiVendor,
  ListPRSeleksiVendor,
  DetailSeleksiVendor,
  TambahSeleksiVendor,
  UbahSeleksiVendor,

  // purchase order
  PurchaseOrder,
  SeleksiVendorPurchaseOrder,
  DetailPurchaseOrder,
  TambahPurchaseOrder,
  UbahPurchaseOrder,

  // Permintaan Upah Produksi
  PermintaanUpahProduksi,
  DataPermintaanUpahProduksi,
  ListPermintaan,

  // Permintaan Subkon Produksi
  PermintaanSubkonProduksi,
  DataPermintaanSubkonProduksi,
  ListPermintaanSubkon,

  // TransferUpahProduksi
  ListTransferUpahProduksi,
  ListPermintaanUpahProduksi,
  TambahTransferUpahProduksi,

  // TransferUpahProduksi
  ListTransferSubkonProduksi,
  ListPermintaanSubkonProduksi,
  TambahTransferSubkonProduksi,

  RegistrasiUpah,
  RegistrasiSubkon,
} from "../pages/Transaksi"

import Logo from "../assets/image/sadhana.png"

export default {
  LOGO: Logo,
  MODUL: "Procurement",

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: "Dashboard",
      link: "/",
      type: "menu",
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ["SUPA", "PRO"],
    },
    {
      text: "Master Data",
      type: "dropdown",
      icon: <IoServerOutline />,
      hak: ["SUPA", "PRO"],
      menu: [
        {
          text: "Vendor",
          link: "/master/vendor",
          hak: ["SUPA", "PRO_MAS_VND"],
        },
        {
          text: "Prakualifikasi Vendor",
          link: "/master/prakualifikasi-vendor",
          hak: ["SUPA", "PRO_MAS_PKV"],
        },
        {
          text: "Kualifikasi Vendor",
          link: "/master/kualifikasi-vendor",
          hak: ["SUPA", "PRO_MAS_KV"],
        },
        {
          text: "Harga Satuan Rata-Rata",
          link: "/master/harga-satuan-rata-rata",
          hak: ["SUPA", "PRO_MAS_HPS"],
        },
        {
          text: "Kelompok Upah",
          link: "/master/kelompok-upah-subcont",
          hak: ["SUPA", "PRO_MAS_KLUS"],
        },
        {
          text: "Item Upah",
          link: "/master/item-upah",
          hak: ["SUPA", "PRO_MAS_IUP"],
        },
        // {
        //   text: "Item Subcont",
        //   link: "/master/item-subcont",
        //   hak: ["SUPA", "PRO_MAS_ISB"],
        // },
      ],
    },
    {
      text: "Transaksi",
      type: "dropdown",
      icon: <IoCashOutline />,
      hak: ["SUPA", "PRO"],
      menu: [
        // {
        //   text: "Coming Soon",
        //   link: "",
        //   hak: ["SUPA", "PRO"],
        // },
        {
          text: "Seleksi Vendor",
          link: "/transaksi/seleksi-vendor",
          hak: ["SUPA", "PRO_TRN_SVD"],
        },
        {
          text: "Purchase Order",
          link: "/transaksi/purchase-order",
          hak: ["SUPA", "PRO_TRN_PO"],
        },
        {
          text: "Transfer Upah Produksi",
          link: "/transaksi/transfer-upah-produksi",
          hak: ["SUPA", "PRO_TRN_TUP"],
        },
        // {
        //   text: "Transfer Subkon Produksi",
        //   link: "/transaksi/transfer-subkon-produksi",
        //   hak: ["SUPA", "PRO_TRN_TSP"],
        // }

        //   // {
        //   //   text: "Transfer Upah Job Mix",
        //   //   link: "/transaksi/transfer-upah-job-mix",
        //   //   hak: ["SUPA", "PRO_TRN_PUP"],
        //   // },
        //   // {
        //   //   text: "Transfer Subcont Job Mix",
        //   //   link: "/transaksi/transfer-subkon-job-mix",
        //   //   hak: ["SUPA", "PRO_TRN_PSP"],
        //   // },
      ],
    },
    // {
    //   text: "Laporan",
    //   type: "dropdown",
    //   icon: <IoDocumentOutline />,
    //   hak: ["SUPA", "PRO"],
    //   menu: [],
    // },
  ],

  ROUTES: [
    {
      exact: true,
      path: "/",
      page: Dashboard,
      hak: ["SUPA", "PRO"],
    },

    //MASTER
    // VENDOR
    {
      exact: true,
      path: "/master/vendor",
      page: Vendor,
      hak: ["SUPA", "PRO_MAS_VND"],
    },
    {
      exact: true,
      path: "/master/vendor/detail/:id",
      page: DetailVendor,
      hak: ["SUPA", "PRO_MAS_VND"],
    },
    {
      exact: true,
      path: "/master/vendor/tambah",
      page: TambahVendor,
      hak: ["SUPA", "PRO_MAS_VND"],
    },
    {
      exact: true,
      path: "/master/vendor/:id",
      page: UbahVendor,
      hak: ["SUPA", "PRO_MAS_VND"],
    },

    // PRAKUALIFIKASI VENDOR
    {
      exact: true,
      path: "/master/prakualifikasi-vendor",
      page: PrakualifikasiVendor,
      hak: ["SUPA", "PRO_MAS_PKV"],
    },
    {
      exact: true,
      path: "/master/prakualifikasi-vendor/calon-vendor",
      page: CalonVendor,
      hak: ["SUPA", "PRO_MAS_PKV"],
    },
    {
      exact: true,
      path: "/master/prakualifikasi-vendor/tambah/:id",
      page: TambahPrakualifikasiVendor,
      hak: ["SUPA", "PRO_MAS_PKV"],
    },
    {
      exact: true,
      path: "/master/prakualifikasi-vendor/ubah/:id",
      page: UbahPrakualifikasiVendor,
      hak: ["SUPA", "PRO_MAS_PKV"],
    },

    // HARGA PERKIRAAN SENDIRI
    {
      // text: "Harga Perkiraan Sendiri",
      exact: true,
      path: "/master/harga-satuan-rata-rata",
      page: HargaPerkiraanSendiri,
      hak: ["SUPA", "PRO_MAS_HPS"],
    },

    // KUALIFIKASI VENDOR
    {
      exact: true,
      path: "/master/kualifikasi-vendor",
      page: KualifikasiVendor,
      hak: ["SUPA", "PRO_MAS_KV"],
    },
    {
      exact: true,
      path: "/master/kualifikasi-vendor/kualifikasi/:id",
      page: Kualifikasi,
      hak: ["SUPA", "PRO_MAS_KV"],
    },
    {
      exact: true,
      path: "/master/kualifikasi-vendor/detail/:id",
      page: DetailKualifikasiVendor,
      hak: ["SUPA", "PRO_MAS_KV"],
    },
    {
      exact: true,
      path: "/master/item-upah",
      page: ItemUpah,
      hak: ["SUPA", "PRO_MAS_IUP"],
    },
    {
      exact: true,
      path: "/master/item-subcont",
      page: ItemSubcont,
      hak: ["SUPA", "PRO_MAS_ISB"],
    },
    {
      exact: true,
      path: "/master/kelompok-upah-subcont",
      page: KelompokUpahSubcont,
      hak: ["SUPA", "PRO_MAS_KLUS"],
    },

    // TRANSAKSI
    // SELEKSI VENDOR
    {
      exact: true,
      path: "/transaksi/seleksi-vendor",
      page: SeleksiVendor,
      hak: ["SUPA", "PRO_TRN_SVD"],
    },
    {
      exact: true,
      path: "/transaksi/seleksi-vendor/purchase-request",
      page: ListPRSeleksiVendor,
      hak: ["SUPA", "PRO_TRN_SVD"],
    },
    {
      exact: true,
      path: "/transaksi/seleksi-vendor/detail/:id",
      page: DetailSeleksiVendor,
      hak: ["SUPA", "PRO_TRN_SVD"],
    },
    {
      exact: true,
      path: "/transaksi/seleksi-vendor/tambah/:id",
      page: TambahSeleksiVendor,
      hak: ["SUPA", "PRO_TRN_SVD"],
    },
    {
      exact: true,
      path: "/transaksi/seleksi-vendor/ubah/:id",
      page: UbahSeleksiVendor,
      hak: ["SUPA", "PRO_TRN_SVD"],
    },

    // PURCHASE ORDER
    {
      exact: true,
      path: "/transaksi/purchase-order",
      page: PurchaseOrder,
      hak: ["SUPA", "PRO_TRN_PO"],
    },
    {
      exact: true,
      path: "/transaksi/purchase-order/vendor-terseleksi",
      page: SeleksiVendorPurchaseOrder,
      hak: ["SUPA", "PRO_TRN_PO"],
    },
    {
      exact: true,
      path: "/transaksi/purchase-order/detail/:id",
      page: DetailPurchaseOrder,
      hak: ["SUPA", "PRO_TRN_PO"],
    },
    {
      exact: true,
      path: "/transaksi/purchase-order/tambah/:id",
      page: TambahPurchaseOrder,
      hak: ["SUPA", "PRO_TRN_PO"],
    },
    {
      exact: true,
      path: "/transaksi/purchase-order/ubah/:id",
      page: UbahPurchaseOrder,
      hak: ["SUPA", "PRO_TRN_PO"],
    },

    // Permintaan Upah Produksi
    {
      exact: true,
      path: "/transaksi/transfer-upah-job-mix",
      page: PermintaanUpahProduksi,
      hak: ["SUPA", "PRO_TRN_PUP"],
    },
    {
      exact: true,
      path: "/transaksi/transfer-upah-job-mix/list-permintaan",
      page: ListPermintaan,
      hak: ["SUPA", "PRO_TRN_PUP"],
    },
    {
      exact: true,
      path: "/transaksi/transfer-upah-job-mix/:id_jobmix_permintaan",
      page: DataPermintaanUpahProduksi,
      hak: ["SUPA", "PRO_TRN_PUP"],
    },

    // Permintaan Subkon Produksi
    {
      exact: true,
      path: "/transaksi/transfer-subkon-job-mix",
      page: PermintaanSubkonProduksi,
      hak: ["SUPA", "PRO_TRN_PSP"],
    },
    {
      exact: true,
      path: "/transaksi/transfer-subkon-job-mix/list-permintaan",
      page: ListPermintaanSubkon,
      hak: ["SUPA", "PRO_TRN_PSP"],
    },
    {
      exact: true,
      path: "/transaksi/transfer-subkon-job-mix/:id_jobmix_permintaan",
      page: DataPermintaanSubkonProduksi,
      hak: ["SUPA", "PRO_TRN_PSP"],
    },

    // Transfer Upah Produksi
    {
      exact: true,
      path: "/transaksi/transfer-upah-produksi",
      page: ListTransferUpahProduksi,
      hak: ["SUPA", "PRO_TRN_TUP"],
    },
    {
      exact: true,
      path: "/transaksi/transfer-upah-produksi/list-permintaan-produksi",
      page: ListPermintaanUpahProduksi,
      hak: ["SUPA", "PRO_TRN_TUP"],
    },
    {
      exact: true,
      path: "/transaksi/transfer-upah-produksi/tambah/:id_permintaan_produksi",
      page: TambahTransferUpahProduksi,
      hak: ["SUPA", "PRO_TRN_TUP"],
    },

    // Transfer Subkon Produksi
    {
      exact: true,
      path: "/transaksi/transfer-subkon-produksi",
      page: ListTransferSubkonProduksi,
      hak: ["SUPA", "PRO_TRN_TSP"],
    },
    {
      exact: true,
      path: "/transaksi/transfer-subkon-produksi/list-permintaan-produksi",
      page: ListPermintaanSubkonProduksi,
      hak: ["SUPA", "PRO_TRN_TSP"],
    },
    {
      exact: true,
      path: "/transaksi/transfer-subkon-produksi/tambah/:id_permintaan_produksi",
      page: TambahTransferSubkonProduksi,
      hak: ["SUPA", "PRO_TRN_TSP"],
    },
  ],
}
