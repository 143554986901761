import Services from "services"

class TransferUpahProduksiApi {
  get(params) {
    return Services.get("/transfer_produksi_upah/page", { params })
  }

  getPreform(params) {
    return Services.get("/transfer_produksi_upah/list_permintaan", { params })
  }

  getSingle(params) {
    return Services.get("/transfer_produksi_upah/single", { params })
  }

  getHistory(params) {
    return Services.get("/transfer_produksi_upah/histori", { params })
  }

  getNomor(params) {
    return Services.get("/transfer_produksi_upah/no_baru", { params })
  }

  getDropdown(params) {
    return Services.get("/transfer_produksi_upah/dropdown", { params })
  }

  save(data) {
    return Services.post("/transfer_produksi_upah", data)
  }
}

export default new TransferUpahProduksiApi()