// React
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

// API
import axios from "axios";
import { PermintaanUpahProduksiApi } from "api";

// Component
import {
  ActionButton, BackButton, DataStatus, Input, 
  TextArea, SelectSearch, DatePicker, PopUpAlert,
  CRUDLayout, THead, Tr, ThFixed, Th, TBody, Td,
  TdFixed
} from "components";
import { DateConvert, RupiahConvert } from "utilities";
import { Card, Col, Row, Modal } from "react-bootstrap";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

const DataPermintaanUpahProduksi = ({ setNavbarTitle }) => {
  const title = "Transfer Upah Job Mix";
  const history = useHistory();
  const { id_jobmix_permintaan } = useParams()

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  const [dataForm, setDataForm] = useState({
    id_jobmix_permintaan: "",
    tgl_jobmix_permintaan: "",
    no_jobmix_permintaan: "",
    id_jobmix: "",
    no_jobmix: "",
    id_buaso: "",
    nama_buaso: "",
    id_item_permintaan: "",
    kode_item: "",
    nama_item: "",
    qty_permintaan: "",
    qty_transfer: "",
    id_satuan: "",
    kode_satuan: "",
    nama_satuan: "",
    id_pabrik: "",
    kode_pabrik: "",
    nama_pabrik: "",
  });

  // Atribut
  const [dataAtribut, setDataAtribut] = useState({
    vendor: [],
    no_baru: "",
  });

  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
    status: false,
  });

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);

    setIsLoading(true)
    
    axios.all([
      PermintaanUpahProduksiApi.single_permintaan({ id_jobmix_permintaan }),
      PermintaanUpahProduksiApi.dropdown({ tipe: 'vendor' }),
      PermintaanUpahProduksiApi.no_baru({ tanggal: DateConvert(new Date()).default })
    ])
      .then(axios.spread((res, vendor, no_baru) => {
        setDataAtribut({
          vendor: vendor.data.data,
          no_baru: no_baru.data.data
        })

        setDataForm(res.data.data)
      }))
      .finally(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle]);

  const formInitialValues = {
    id_jobmix_permintaan,
    tgl_jobmix_transfer_upah: DateConvert(new Date()).default,
    no_jobmix_transfer_upah: dataAtribut.no_baru,
    id_vendor: "",
    id_item_buaso: dataForm.id_item_permintaan,
    qty_transfer: "",
    harga_satuan: "0",
    catatan: ""
  };

  const formValidationSchema = Yup.object().shape({
    tgl_jobmix_transfer_upah: Yup.date()
      .typeError("Format Tanggal salah")
      .required("Tanggal Job Mix Transfer Upah wajib diisi"),
    id_vendor: Yup.string().required("Vendor wajib diisi"),
    qty_transfer: Yup.number()
      .typeError("Format Angka salah")
      .required("Qty Transfer wajib diisi"),
    harga_satuan: Yup.string().required("Harga Satuan wajib diisi"),
  });

  const formSubmitHandler = values => {
    PermintaanUpahProduksiApi.create(values)
      .then(() => {
        // SUCCESS ALERT
        setAlertConfig({
          show: true,
          type: "success",
          variant: "primary",
          text: "Tambah data berhasil!",
        })
      })
      .catch(() => {
        // ERROR ALERT
        setAlertConfig({
          show: true,
          type: "danger",
          variant: "danger",
          text: `Tambah data gagal!`,
        })
      })
  }

  const TambahData = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td className="align-top">{title}</td>
        <td className="pl-4 pr-2 align-top">:</td>
        <td className="align-top">{value}</td>
      </tr>
    )

    return (
      <>
        {dataForm ? (
          <>
            <Row>
              <Col md>
                <table style={{ fontSize: "14px" }}>
                  <tbody>
                    <InfoItem
                      title="Tgl. Permintaan Job Mix"
                      value={dataForm?.tgl_jobmix_permintaan ? DateConvert(new Date(dataForm.tgl_jobmix_permintaan)).detail : "-"}
                    />
                    <InfoItem
                      title="No. Permintaan Job Mix"
                      value={dataForm?.no_jobmix_permintaan ?? "-"}
                    />
                    <InfoItem
                      title="No. Job Mix"
                      value={dataForm?.no_jobmix ?? "-"}
                    />
                  </tbody>
                </table>
              </Col>

              <Col md>
                <table style={{ fontSize: "14px" }}>
                  <tbody>
                    <InfoItem
                      title="Item Upah"
                      value={dataForm?.nama_item && dataForm?.kode_item ? `(${dataForm.kode_item}) ${dataForm?.nama_item}` : "-"}
                    />
                    <InfoItem
                      title="Qty. Permintaan Job Mix"
                      value={dataForm?.qty_permintaan ?? 0}
                    />
                    <InfoItem
                      title="Pabrik"
                      value={dataForm?.nama_pabrik ?? "-"}
                    />
                  </tbody>
                </table>
              </Col>
            </Row>
            <hr />

            <Formik
              enableReinitialize
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleSubmit,
                setValues,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6">
                      <DatePicker
                        label="Tgl. Transfer Upah Job Mix"
                        selected={values.tgl_jobmix_transfer_upah ? new Date(values.tgl_jobmix_transfer_upah) : ''}
                        onChange={(date) => {
                          PermintaanUpahProduksiApi.no_baru({ tanggal: DateConvert(new Date(date)).default })
                            .then(res => setValues({
                              ...values,
                              tgl_jobmix_transfer_upah: DateConvert(date).default,
                              no_jobmix_transfer_upah: res.data.data
                            }))
                        }}
                        error={errors.qty && touched.qty && true}
                        errorText={errors.qty}
                      />
                    </Col>

                    <Col md="6">
                      <Input
                        label="No. Transfer Upah Job Mix"
                        value={values.no_jobmix_transfer_upah}
                        readOnly
                      />
                    </Col>

                    <Col md="6">
                      <SelectSearch
                        label="Vendor"
                        placeholder="Pilih Vendor"
                        onChange={(val) => setValues({
                          ...values,
                          id_vendor: val.value,
                        })}
                        option={dataAtribut.vendor.map((val) => {
                          return {
                            value: val.id_vendor,
                            label: val.nama_vendor,
                          }
                        })}
                        error={errors.id_vendor && touched.id_vendor && true}
                        errorText={errors.id_vendor}
                      />
                    </Col>

                    <Col md="6">
                      <Input
                        label="Item Upah"
                        value={dataForm?.nama_item && dataForm?.kode_item ? `(${dataForm.kode_item}) ${dataForm?.nama_item}` : "-"}
                        readOnly
                      />
                    </Col>

                    <Col md="3">
                      <Input
                        label="Harga Satuan"
                        placeholder="Harga Satuan"
                        onChange={e => {
                          const data = RupiahConvert(e.target.value).default

                          setValues({
                            ...values,
                            harga_satuan: data
                          })
                        }}
                        value={RupiahConvert(String(values.harga_satuan || "0")).detail}
                        error={errors.harga_satuan && touched.harga_satuan && true}
                        errorText={errors.harga_satuan}
                      />
                    </Col>

                    <Col md="3">
                      <Input
                        label="Qty. Transfer"
                        placeholder="Qty. Transfer"
                        name="qty_transfer"
                        onChange={handleChange}
                        value={values.qty_transfer}
                        error={errors.qty_transfer && touched.qty_transfer && true}
                        errorText={errors.qty_transfer}
                      />
                    </Col>

                    <Col md="3">
                      <Input
                        label="Satuan Transfer"
                        placeholder="Satuan Transfer"
                        value={dataForm?.nama_satuan ?? '-'}
                        readOnly={true}
                      />
                    </Col>

                    <Col md="3">
                      <Input
                        label="Jumlah"
                        placeholder="Jumlah"
                        value={values.harga_satuan && values.qty_transfer ? RupiahConvert((parseInt(values.harga_satuan) * parseInt(values.qty_transfer)).toString()).detail : 'Rp0'}
                        readOnly={true}
                      />
                    </Col>
                  </Row>

                  <TextArea
                    label="Catatan"
                    placeholder="Catatan"
                    name="catatan"
                    onChange={handleChange}
                    value={values.catatan}
                    rows={3}
                    error={errors.catatan && touched.catatan && true}
                    errorText={errors.catatan}
                  />

                  <DataHistory />

                  <div className="text-right">
                    <ActionButton
                      type="submit"
                      text="Simpan"
                      variant="primary"
                      loading={isSubmitting}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </>
    );
  }

  const DataHistory = () => {
    // STATES
    const [statusHistory, setStatusHistory] = useState({
      loading: false,
      status: true,
    })
    const [dataHistory, setDataHistory] = useState([])

    useEffect(() => {
      setStatusHistory({
        loading: true,
        status: false,
      })
      setDataHistory([])

      PermintaanUpahProduksiApi.jobmix_transfer_upah({ id_jobmix_permintaan })
        .then(res => {
          setDataHistory(res.data.data)

          setStatusHistory({
            loading: false,
            status: true,
          })
        })
        .catch(() => setStatusHistory({
          loading: false,
          status: false,
        }))
    }, [])

    const InfoItem = ({ title, value }) => (
      <tr>
        <td className="align-top">{title}</td>
        <td className="pl-4 pr-2 align-top">:</td>
        <td className="align-top">{value}</td>
      </tr>
    )

    return (
      <>
        <h6>Histori Transfer Upah Job Mix</h6>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Tgl. Transfer Upah</ThFixed>
              <ThFixed>No. Transfer Upah</ThFixed>
              <Th>Item Upah</Th>
              <Th>Qty. Transfer Upah</Th>
              <Th>Vendor</Th>
              <Th>Harga Satuan</Th>
              <Th>Jumlah</Th>
            </Tr>
          </THead>
          <TBody>
            {statusHistory.loading ?
              <DataStatus loading text="Memuat Data" />
            :
              statusHistory.status ? 
                dataHistory.length > 0 ? dataHistory.map((val, index) => {
                  return (
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>{val.tgl_jobmix_transfer_upah ? DateConvert(new Date(val.tgl_jobmix_transfer_upah)).defaultDMY : '-'}</TdFixed>
                      <TdFixed>{val.no_jobmix_transfer_upah ?? '-'}</TdFixed>
                      <Td>{val.nama_item && val.kode_item ? `(${val.kode_item}) ${val.nama_item}` : '-'}</Td>
                      <Td>{val.qty_transfer ? parseInt(val.qty_transfer) : '-'}</Td>
                      <Td>{val.nama_vendor ?? '-'}</Td>
                      <Td>{val.harga_satuan ? RupiahConvert(parseInt(val.harga_satuan).toString()).detail : '-'}</Td>
                      <Td>{val.harga_satuan && val.qty_transfer ? RupiahConvert((parseInt(val.harga_satuan) * parseInt(val.qty_transfer)).toString()).detail : '-'}</Td>
                    </Tr>
                  )
                })
                :
                  <Tr>
                    <Td className="text-center" colSpan="8">
                      <DataStatus text="Tidak Ada Data" />
                    </Td>
                  </Tr>
              :
                <DataStatus text="Gagal Memuat Data" />
            }
          </TBody>
        </CRUDLayout.Table>
      </>
    )
  }

  return (
    <>
      <Card>
        <Card.Header className="d-flex flex-row justify-content-between align-items-center">
          <b>Detail {title}</b>
          <BackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </Card.Header>
        <Card.Body>
          <PopUpAlert
            show={alertConfig.show}
            type={alertConfig.type}
            title={alertConfig.text}
            confirmButtonVariant={alertConfig.type}
            onHide={() => setAlertConfig({
              ...alertConfig,
              show: false,
            })}
            onConfirm={() => {
              setAlertConfig({
                ...alertConfig,
                show: false,
              })

              if (alertConfig.type === 'success') {
                history.push('/transaksi/transfer-upah-job-mix')
              }
            }}
          />
          {isLoading === true ? (
            <DataStatus loading={true} text="Memuat data..." />
          ) : dataForm !== undefined ? (
            <>
              <TambahData />
            </>
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default DataPermintaanUpahProduksi;
