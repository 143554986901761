import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from "formik"
import { PurchaseOrderApi } from 'api'
import Axios from "axios"

const ModalFilterPO = ({ modalFilter, setModalFilter, data, setData }) => {
  const [dropdownVendor, setDropdownVendor] = useState([{ label: "Semua", value: undefined }])
  const [dropdownSV, setDropdownSV] = useState([{ label: "Semua", value: undefined }])
  const [isLoading, setIsLoading] = useState(false)

  const getDropdown = () => {
    setIsLoading(true)

    Axios.all([
      PurchaseOrderApi.dropdownVendor(),
      PurchaseOrderApi.dropdownItemPO()
    ])
      .then(Axios.spread(( ven, sv ) => {
        console.log(ven)
        const mapVendor = ven?.data?.data ? ven?.data?.data.map(val => ({ label: val.nama_vendor, value: val.id_vendor })) : []
        const mapSV = sv?.data?.data ? sv?.data?.data.map(val => ({ label: val.nama_item, value: val.id_item_buaso })) : []

        setDropdownVendor([ ...dropdownVendor, ...mapVendor ])
        setDropdownSV([ ...dropdownSV, ...mapSV ])
      }))
      .finally(() => setIsLoading(false))
  };

  useEffect(() => {
    getDropdown()
  }, [])

  const formInitialValues = {
    tgl_sv: data?.filter?.tgl_sv,
    // tgl_sv_selesai: data?.filter?.tgl_sv_selesai,
    id_vendor: data?.filter?.id_vendor,
    id_item_sv: data?.filter?.id_item_sv,
  }
  const formSubmitHandler = (values) => {
      const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

      if (checkActive) {
        setData({ 
            filter: {
                ...values,
                active: true
            },
            pagination: {
                ...data.pagination,
                page: 1
            }
        })
      } else { 
          setData({ 
              ...data,
              filter: {
                  ...values,
                  active: false
              }
          })
      }

      setModalFilter(false)
  }
  const onChangeSV = (dates, values, setValues) => {
    const selected = dates ? DateConvert(dates).default : null
    setValues({ ...values, tgl_sv: selected })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
        tgl_sv: undefined,
        // tgl_sv_selesai: undefined,
        id_vendor: undefined,
        id_item_sv: undefined,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (

      <Modal
        show={modalFilter}
        onHide={() => setModalFilter(false)}>
          
        <Modal.Header closeButton><b>Filter Data</b></Modal.Header>
        <Modal.Body>
          <DatePicker 
            label="Tgl. Seleksi Vendor"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal seleksi vendor"
            selected={values.tgl_sv ? new Date(values.tgl_sv) : ""}
            onChange={(dates) => onChangeSV(dates, values, setValues)}
          />
          <SelectSearch
            key={values.id_vendor}
            label="Vendor"
            placeholder="Pilih vendor"
            defaultValue={dropdownVendor.find(item => item.value === values.id_vendor)}
            option={dropdownVendor}
            onChange={val => setFieldValue('id_vendor', val.value)}
            loading={isLoading}
          />
          <SelectSearch
            key={values.id_item_sv}
            label="Item Seleksi Vendor"
            placeholder="Pilih item seleksi vendor"
            defaultValue={dropdownSV.find(item => item.value === values.id_item_sv)}
            option={dropdownSV}
            onChange={val => setFieldValue('id_item_sv', val.value)}
            loading={isLoading}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => onResetButtonClick(values, setValues)}
          >
            RESET
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            FILTER
          </Button>
        </Modal.Footer>
      </Modal >

      )}
  </Formik>
  )
}

export default ModalFilterPO