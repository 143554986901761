import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from "formik"

const ModalFilter = ({ modalConfig, setModalConfig, data, setData }) => {
  const [dropdownKeperluan, setDropdownKeperluan] = useState([
    { label: "Semua", value: undefined },
    { label: "Stok", value: "stok" },
    { label: "Produksi", value: "produksi" },
  ])
  const [dropdownStatusSV, setDropdownStatusSV] = useState([
    { label: "Semua", value: undefined },
    { label: "Sudah Dibuatkan PO", value: true },
    { label: "Belum Dibuatkan PO", value: false },
  ])
  const formInitialValues = {
    tgl_seleksi_vendor_mulai: data?.filter?.tgl_seleksi_vendor_mulai,
    tgl_seleksi_vendor_selesai: data?.filter?.tgl_seleksi_vendor_selesai,
    tgl_purchase_request_mulai: data?.filter?.tgl_purchase_request_mulai,
    tgl_purchase_request_selesai: data?.filter?.tgl_purchase_request_selesai,
    tgl_pemakaian_mulai: data?.filter?.tgl_pemakaian_mulai,
    tgl_pemakaian_selesai: data?.filter?.tgl_pemakaian_selesai,
    keperluan: data?.filter?.keperluan,
    status_purchase_order: data?.filter?.status_purchase_order,
  }
  const formSubmitHandler = (values) => {
      const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

      if (checkActive) {
        setData({ 
            filter: {
                ...values,
                active: true
            },
            pagination: {
                ...data.pagination,
                page: 1
            }
        })
      } else { 
          setData({ 
              ...data,
              filter: {
                  ...values,
                  active: false
              }
          })
      }

      setModalConfig(prev => ({...prev, show: false}))
  }
  const onChangeSeleksiVendor = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_seleksi_vendor_mulai: startDate,
        tgl_seleksi_vendor_selesai: endDate,
    })
  }
  const onChangePurchaseRequest = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_purchase_request_mulai: startDate,
        tgl_purchase_request_selesai: endDate,
    })
  }
  const onChangePemakaian = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_pemakaian_mulai: startDate,
        tgl_pemakaian_selesai: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_seleksi_vendor_mulai: undefined,
      tgl_seleksi_vendor_selesai: undefined,
      tgl_purchase_request_mulai: undefined,
      tgl_purchase_request_selesai: undefined,
      tgl_pemakaian_mulai: undefined,
      tgl_pemakaian_selesai: undefined,
      keperluan: undefined,
      status_purchase_order: undefined,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (

      <Modal
        show={modalConfig.show && modalConfig.type === 'filter'}
        onHide={() => setModalConfig(prev => ({ ...prev, show: false }))}>
          
        <Modal.Header closeButton><b>Filter Data</b></Modal.Header>
        <Modal.Body>
          <DatePicker
            selectsRange
            label="Tgl. Seleksi Vendor"
            placeholderText="Pilih tanggal seleksi vendor"
            dateFormat="dd/MM/yyyy"
            startDate={values.tgl_seleksi_vendor_mulai ? new Date(values.tgl_seleksi_vendor_mulai) : ''}
            endDate={values.tgl_seleksi_vendor_selesai ? new Date(values.tgl_seleksi_vendor_selesai) : ''}
            onChange={(dates) => onChangeSeleksiVendor(dates, values, setValues)}
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Purchase Request"
            placeholderText="Pilih tanggal purchase request"
            dateFormat="dd/MM/yyyy"
            startDate={values.tgl_purchase_request_mulai ? new Date(values.tgl_purchase_request_mulai) : ''}
            endDate={values.tgl_purchase_request_selesai ? new Date(values.tgl_purchase_request_selesai) : ''}
            onChange={(dates) => onChangePurchaseRequest(dates, values, setValues)}
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Pemakaian"
            placeholderText="Pilih tanggal pemakaian"
            dateFormat="dd/MM/yyyy"
            startDate={values.tgl_pemakaian_mulai ? new Date(values.tgl_pemakaian_mulai) : ''}
            endDate={values.tgl_pemakaian_selesai ? new Date(values.tgl_pemakaian_selesai) : ''}
            onChange={(dates) => onChangePemakaian(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            key={values.keperluan}
            label="Keperluan"
            placeholder="Pilih keperluan"
            defaultValue={dropdownKeperluan?.find(item => item.value === values.keperluan)}
            option={dropdownKeperluan}
            onChange={val => setFieldValue('keperluan', val.value)}
          />
          <SelectSearch
            key={values.status_purchase_order}
            label="Status Purchase Order"
            placeholder="Pilih status purchase order"
            defaultValue={dropdownStatusSV?.find(item => item.value === values.status_purchase_order)}
            option={dropdownStatusSV}
            onChange={val => setFieldValue('status_purchase_order', val.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => onResetButtonClick(values, setValues)}
          >
            RESET
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            FILTER
          </Button>
        </Modal.Footer>
      </Modal >

      )}
  </Formik>
  )
}

export default ModalFilter