import Services from "services";

class RegItemUpahApi {
  getPage(page, dataLength, key) {
    return Services.get(`/upah/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  getBuaso() {
    return Services.get("/buaso");
  }
  getSatuan() {
    return Services.get("/satuan/dropdown");
  }
  getKelompok() {
    return Services.get("/upah/kelompok");
  }
  getPabrikan() {
    return Services.get("/pabrikan/dropdown");
  }
  getBuasoUpah() {
    return Services.get("/upah/kelompok?id_buaso=2");
  }
  generateKodeItem() {
    return Services.get("/upah/no_baru");
  }
  create(data) {
    return Services.post("/upah", data);
  }
  update(data) {
    return Services.put("/upah", data);
  }
  delete(id_upah) {
    return Services.post("/upah/delete", id_upah);
  }
  search(key) {
    return Services.get(`upah/page/?q=${key}`);
  }
  show(id_upah) {
    return Services.put("/upah/show", id_upah);
  }
  hide(id_upah) {
    return Services.put("/upah/hide", id_upah);
  }
  export() {
    return Services.get("/upah/xlsx");
  }
  checkDuplicate(params) {
    return Services.get("/upah/check_duplicate", { params });
  }
}

export default new RegItemUpahApi();
