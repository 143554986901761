import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DatePicker,
  TextArea,
  Approval,
} from "../../../../components";
import { DateConvert, RupiahConvert } from "../../../../utilities";
import { PurchaseOrderApi } from "../../../../api";
import { IoAddOutline, IoTrashOutline } from "react-icons/io5";

const UbahPurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataPurchaseOrder, setDataPurchaseOrder] = useState({});
  const [dataPODetail, setDataPODetail] = useState([]);
  const [dataSVDetail, setDataSVDetail] = useState([]);
  const [dataVendor, setDataVendor] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderApi.getSingle({ id_purchase_order: id })
      .then(({ data }) => {
        const dataPO = data.data;
        const dataDetail = [];

        setDataPurchaseOrder(dataPO);

        dataPO.po_detail &&
          dataPO.po_detail.map((val) => {
            const processed = {
              id_item_buaso: val.id_item_buaso,
              qty_order: val.qty_order,
              harga_kesepakatan: val.harga_kesepakatan,
              kode_item: val.kode_item,
              nama_item: val.nama_item,
              id_seleksi_vendor_detail: val.id_seleksi_vendor_detail[0],
            };
            dataDetail.push(processed);
          });

        setDataPODetail(dataDetail);

        PurchaseOrderApi.getSingleVendor({ id_vendor: data.data.id_vendor })
          .then(({ data }) => {
            const dataSV = data.data;
            const dataDetail = [];

            setDataVendor(dataSV);

            dataSV.detail &&
              dataSV.detail.map((val) => {
                const processed = {
                  id_item_buaso: val.id_item_buaso,
                  qty_order: val.qty_order,
                  harga_kesepakatan: val.harga_kesepakatan,
                  kode_item: val.kode_item,
                  nama_item: val.nama_item,
                  id_seleksi_vendor_detail: val.id_seleksi_vendor_detail,
                };

                dataDetail.push(processed);
              });

            setDataSVDetail(dataDetail);
          })
          .catch(() => {
            setIsFetchingFailed(true);
            setAlertConfig({
              show: true,
              variant: "danger",
              text: "Data gagal dimuat!",
            });
          })
          .finally(() => setIsPageLoading(false));
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle("Ubah Purchase Order");
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const PageContent = () => {
    const [dataListItemPurchaseOrder, setDataListItemPurchaseOrder] = useState([]);
    const [dataForm, setDataForm] = useState({
      tgl_purchase_order: dataPurchaseOrder.tgl_purchase_order,
      no_purchase_order: dataPurchaseOrder.no_purchase_order,
    });
    const dataPengaju = dataPurchaseOrder?.stakeholder ? dataPurchaseOrder.stakeholder : [];

    const formInitialValues = {
      id_vendor: dataPurchaseOrder.id_vendor,
      id_purchase_order: dataPurchaseOrder.id_purchase_order,
      // id_purchase_request: dataPurchaseOrder.id_purchase_request,
      tgl_purchase_order: dataForm.tgl_purchase_order,
      no_purchase_order: dataForm.no_purchase_order,
      tgl_pengiriman: dataPurchaseOrder.tgl_pengiriman,
      tgl_jatuh_tempo: dataPurchaseOrder.tgl_jatuh_tempo,
      alamat_tujuan_pengiriman: dataPurchaseOrder.alamat_tujuan_pengiriman
        ? dataPurchaseOrder.alamat_tujuan_pengiriman
        : "",
      keterangan: dataPurchaseOrder.keterangan ? dataPurchaseOrder.keterangan : "",
      diskon: dataPurchaseOrder.diskon ? dataPurchaseOrder.diskon : "",
      ppn: dataPurchaseOrder.ppn ? dataPurchaseOrder.ppn : "",
    };

    const formValidationSchema = Yup.object().shape({
      tgl_purchase_order: Yup.string().required("Pilih tanggal purchase order"),
      no_purchase_order: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
      tgl_pengiriman: Yup.string().required("Pilih tanggal pengiriman"),
      alamat_tujuan_pengiriman: Yup.string().required("Masukan alamat tujuan pengiriman"),
      diskon: Yup.string().required(),
      ppn: Yup.string().required(),
    });

    const formSubmitHandler = (values) => {
      const detailData = dataListItemPurchaseOrder.map((val) => {
        return {
          id_item_buaso: val.id_item_buaso,
          qty_order: val.qty_order,
          harga_kesepakatan: val.harga_kesepakatan,
          id_seleksi_vendor_detail: [val.id_seleksi_vendor_detail],
        };
      });

      const finalValues = {
        ...values,
        detail: detailData,
      };

      PurchaseOrderApi.createOrUpdate(finalValues)
        .then(() => {
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil diubah!",
          });
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal diubah!",
          });
        })
        .finally(() => getInitialData());
    };

    useEffect(() => {
      setDataListItemPurchaseOrder(dataPODetail);

      return () => {};
    }, []);

    const InfoSection = () => {
      const InfoItem = ({ title, value }) => (
        <tr>
          <td>
            <small>{title}</small>
          </td>
          <td>
            <small className="pl-4 pr-2">:</small>
          </td>
          <td>
            <small>{value}</small>
          </td>
        </tr>
      );

      return (
        <>
          <table>
            <tbody>
              <InfoItem
                title="Kode Vendor"
                value={dataVendor.kode_vendor ? dataVendor.kode_vendor : "-"}
              />
              <InfoItem
                title="Vendor"
                value={dataVendor.nama_vendor ? dataVendor.nama_vendor : "-"}
              />
              <InfoItem
                title="Contact Person"
                value={dataVendor.contact_person ? dataVendor.contact_person : "-"}
              />
              <InfoItem
                title="Alamat Vendor"
                value={dataVendor.alamat_vendor ? dataVendor.alamat_vendor : "-"}
              />
            </tbody>
          </table>
          <hr />
        </>
      );
    };

    const FormSection = ({ values, errors, touched, handleChange, setFieldValue }) => {
      const getNoPurchaseOrder = (date) => {
        PurchaseOrderApi.getNomor({ tanggal: date })
          .then(({ data }) => {
            setFieldValue("tgl_purchase_order", date);
            setFieldValue("no_purchase_order", data.data);
          })
          .catch(() => {
            setFieldValue("tgl_purchase_order", "");
            setFieldValue("no_purchase_order", "");
            setAlertConfig({
              show: true,
              variant: "danger",
              text: "Nomor gagal diperoleh!",
            });
          });
      };

      return (
        <>
          <Row>
            <Col>
              <DatePicker
                label="Tgl. Purchase Order"
                className="mt-1"
                selected={values.tgl_purchase_order ? new Date(values.tgl_purchase_order) : ""}
                onChange={(e) => {
                  const value = e.toISOString().slice(0, 10);
                  getNoPurchaseOrder(value);
                }}
                error={errors.tgl_purchase_order && touched.tgl_purchase_order && true}
                errorText={errors.tgl_purchase_order}
              />
            </Col>
            <Col>
              <Input
                type="text"
                label="No. Purchase Order"
                placeholder="Pilih tanggal untuk menentukan nomor"
                value={values.no_purchase_order}
                error={errors.no_purchase_order && touched.no_purchase_order && true}
                errorText={errors.no_purchase_order}
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DatePicker
                label="Tgl. Pengiriman"
                className="mt-1"
                selected={values.tgl_pengiriman ? new Date(values.tgl_pengiriman) : ""}
                onChange={(e) => {
                  const value = e.toISOString().slice(0, 10);
                  setFieldValue("tgl_pengiriman", value);
                }}
                error={errors.tgl_pengiriman && touched.tgl_pengiriman && true}
                errorText={errors.tgl_pengiriman}
              />
              <DatePicker
                label="Tgl. Jatuh Tempo Pembayaran"
                className="mt-1"
                selected={values.tgl_jatuh_tempo ? new Date(values.tgl_jatuh_tempo) : new Date()}
                onChange={(e) => {
                  const value = e.toISOString().slice(0, 10);
                  setFieldValue("tgl_jatuh_tempo", value);
                }}
                error={errors.tgl_jatuh_tempo && touched.tgl_jatuh_tempo && true}
                errorText={errors.tgl_jatuh_tempo}
              />
              <TextArea
                label="Alamat Tujuan Pengiriman"
                name="alamat_tujuan_pengiriman"
                placeholder="Masukan alamat tujuan pengiriman"
                value={values.alamat_tujuan_pengiriman}
                onChange={handleChange}
                error={errors.alamat_tujuan_pengiriman && touched.alamat_tujuan_pengiriman && true}
                errorText={errors.alamat_tujuan_pengiriman}
              />
            </Col>
            <Col>
              <TextArea
                label="Keterangan"
                name="keterangan"
                placeholder="Masukan keterangan"
                value={values.keterangan}
                onChange={handleChange}
                error={errors.keterangan && touched.keterangan && true}
                errorText={errors.keterangan}
                rows={8}
              />
            </Col>
          </Row>
          <hr />
        </>
      );
    };

    const ListItemSeleksiVendor = ({ dataListItemPurchaseOrder, setDataListItemPurchaseOrder }) => {
      return (
        <>
          <div className="py-2 pl-1">
            <b>List Item Seleksi Vendor</b>
          </div>
          {dataSVDetail ? (
            dataSVDetail.length > 0 ? (
              <Table>
                <THead>
                  <Tr>
                    <ThFixed>No.</ThFixed>
                    <ThFixed>Kode Item</ThFixed>
                    <Th>Nama Item</Th>
                    <Th>Qty</Th>
                    <Th>Satuan Beli</Th>
                    <Th>No. Purchase Request</Th>
                    <Th>Tgl. Seleksi</Th>
                    <ThFixed>Aksi</ThFixed>
                  </Tr>
                </THead>
                <TBody>
                  {dataSVDetail.map((val, index) => (
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>{val.kode_item ? val.kode_item : "-"}</TdFixed>
                      <Td>
                        <div style={{ width: "200px" }}>{val.nama_item ? val.nama_item : "-"}</div>
                      </Td>
                      <Td textRight>{val.qty_order ? val.qty_order : "-"}</Td>
                      <Td>{val.satuan_beli ? val.satuan_beli : "-"}</Td>
                      <Td>{val.no_purchase_request ? val.no_purchase_request : "-"}</Td>
                      <Td>{val.tgl_seleksi ? DateConvert(new Date(val.tgl_seleksi)) : "-"}</Td>
                      <Td>
                        <ActionButton
                          size="sm"
                          text={<IoAddOutline />}
                          onClick={() =>
                            setDataListItemPurchaseOrder([...dataListItemPurchaseOrder, val])
                          }
                          disable={dataListItemPurchaseOrder.find(
                            (po) => po.id_seleksi_vendor_detail === val.id_seleksi_vendor_detail
                          )}
                        />
                      </Td>
                    </Tr>
                  ))}
                </TBody>
              </Table>
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
        </>
      );
    };

    const ListItemPurchaseOrderSection = ({
      values,
      errors,
      touched,
      handleChange,
      dataListItemPurchaseOrder,
      setDataListItemPurchaseOrder,
    }) => {
      let total = 0;
      let nilaiDiskon = 0;
      let setelahDiskon = 0;
      let nilaiPPN = 0;
      let setelahPPN = 0;

      const TableFooterItems = ({ text, input, value }) => (
        <Tr>
          <Td colSpan={6} textRight>
            <b>{text}</b>
          </Td>
          <Td textRight colSpan={2}>
            <div className="d-flex align-items-center justify-content-between text-nowrap">
              {input ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div style={{ width: "70px" }}>{input}</div>
                  <div className="pb-1 ml-2">
                    <b>%</b>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div className="text-right">
                <b>{value}</b>
              </div>
            </div>
          </Td>
        </Tr>
      );

      return (
        <>
          <div className="py-2 pl-1">
            <b>List Item Purchase Order</b>
          </div>
          {dataListItemPurchaseOrder ? (
            dataListItemPurchaseOrder.length > 0 ? (
              <>
                <Table>
                  <THead>
                    <Tr>
                      <ThFixed>No.</ThFixed>
                      <ThFixed>Kode Item</ThFixed>
                      <Th>Nama Item</Th>
                      <Th>Satuan Beli</Th>
                      <Th>Qty Order</Th>
                      <Th>Harga Kesepakatan</Th>
                      <Th>Jumlah</Th>
                      <ThFixed>Aksi</ThFixed>
                    </Tr>
                  </THead>
                  <TBody>
                    {dataListItemPurchaseOrder.map((val, index) => {
                      total = total + val.harga_kesepakatan * val.qty_order;
                      nilaiDiskon = values.diskon
                        ? parseInt(values.diskon) === 0
                          ? 0
                          : parseInt((values.diskon / 100) * total)
                        : 0;
                      setelahDiskon = parseInt(total - nilaiDiskon);
                      nilaiPPN = values.ppn
                        ? parseInt(values.ppn) === 0
                          ? 0
                          : parseInt((values.ppn / 100) * setelahDiskon)
                        : 0;
                      setelahPPN = parseInt(setelahDiskon + nilaiPPN);

                      return (
                        <Tr key={index}>
                          <TdFixed>{index + 1}</TdFixed>
                          <TdFixed>{val.kode_item}</TdFixed>
                          <Td>
                            <div style={{ width: "200px" }}>
                              {val.nama_item ? val.nama_item : "-"}
                            </div>
                          </Td>
                          <Td>{val.satuan_beli ? val.satuan_beli : "-"}</Td>
                          <Td textRight>{val.qty_order ? val.qty_order : "-"}</Td>
                          <Td textRight>
                            {RupiahConvert(val.harga_kesepakatan.toString()).detail}
                          </Td>
                          <Td textRight>
                            {
                              RupiahConvert(
                                parseInt(val.qty_order * val.harga_kesepakatan).toString()
                              ).detail
                            }
                          </Td>
                          <Td className="d-flex justify-content-center">
                            <ActionButton
                              size="sm"
                              variant="danger"
                              text={<IoTrashOutline />}
                              onClick={() => {
                                const filterData = dataListItemPurchaseOrder.filter(
                                  (data) => data !== val
                                );
                                setDataListItemPurchaseOrder(filterData);
                              }}
                            />
                          </Td>
                        </Tr>
                      );
                    })}
                    <TableFooterItems
                      text="Total"
                      value={RupiahConvert(parseInt(total).toString()).detail}
                    />
                    <Tr>
                      <Td colSpan={6} textRight>
                        <b>Diskon</b>
                      </Td>
                      <Td textRight colSpan={2}>
                        <div className="d-flex align-items-center justify-content-between text-nowrap">
                          <div className="d-flex justify-content-center align-items-center">
                            <div style={{ width: "70px" }}>
                              <input
                                type="number"
                                name="diskon"
                                className={`form-control form-control-sm ${
                                  errors.diskon && touched.diskon && "border-danger"
                                }`}
                                value={values.diskon}
                                onChange={handleChange}
                                min={0}
                                max={100}
                                required
                              />
                            </div>
                            <div className="pb-1 ml-2">
                              <b>%</b>
                            </div>
                          </div>
                          <div className="text-right">
                            <b>{RupiahConvert(nilaiDiskon.toString()).detail}</b>
                          </div>
                        </div>
                      </Td>
                    </Tr>
                    <TableFooterItems
                      text="Total Setelah Diskon"
                      value={RupiahConvert(setelahDiskon.toString()).detail}
                    />
                    <Tr>
                      <Td colSpan={6} textRight>
                        <b>PPN</b>
                      </Td>
                      <Td textRight colSpan={2}>
                        <div className="d-flex align-items-center justify-content-between text-nowrap">
                          <div className="d-flex justify-content-center align-items-center">
                            <div style={{ width: "70px" }}>
                              <input
                                type="number"
                                name="ppn"
                                className={`form-control form-control-sm ${
                                  errors.ppn && touched.ppn && "border-danger"
                                }`}
                                value={values.ppn}
                                onChange={handleChange}
                                min={0}
                                max={100}
                                required
                              />
                            </div>
                            <div className="pb-1 ml-2">
                              <b>%</b>
                            </div>
                          </div>
                          <div className="text-right">
                            <b>{RupiahConvert(nilaiPPN.toString()).detail}</b>
                          </div>
                        </div>
                      </Td>
                    </Tr>
                    <TableFooterItems
                      text="Total Setelah PPN"
                      value={RupiahConvert(setelahPPN.toString()).detail}
                    />
                  </TBody>
                </Table>
              </>
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
          <hr />
        </>
      );
    };

    const FormCard = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Purchase Order</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {dataPengaju.map(
                (val, index) =>
                  index !== 0 &&
                  val.status_approval !== "PEN" && (
                    <Col sm>
                      <InfoItem
                        title1={
                          val.status_approval === "VER" || val.status_approval === "REV"
                            ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                            : val.status_approval === "APP"
                            ? "Pengesah"
                            : "Di Tolak Oleh"
                        }
                        value1={val.nama_karyawan ?? "-"}
                        title2="Catatan"
                        value2={val.catatan ?? "-"}
                      />
                      {console.log(val.status_approval)}
                    </Col>
                  )
              )}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    const ButtonSection = ({ isSubmitting }) => (
      <div className="d-flex justify-content-end my-3">
        <ActionButton
          type="submit"
          variant="success"
          text="Ubah Data Purchase Order"
          loading={isSubmitting}
        />
      </div>
    );

    return (
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <InfoSection />
            <FormSection
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
            <ListItemSeleksiVendor
              dataListItemPurchaseOrder={dataListItemPurchaseOrder}
              setDataListItemPurchaseOrder={setDataListItemPurchaseOrder}
            />
            <ListItemPurchaseOrderSection
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              dataListItemPurchaseOrder={dataListItemPurchaseOrder}
              setDataListItemPurchaseOrder={setDataListItemPurchaseOrder}
            />
            {dataPengaju.length > 1 && <Approval data={dataPurchaseOrder} />}
            {dataListItemPurchaseOrder && dataListItemPurchaseOrder.length > 0 && (
              <ButtonSection isSubmitting={isSubmitting} />
            )}
          </form>
        )}
      </Formik>
    );
  };

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Ubah Data Purchase Order</b>
        <BackButton onClick={() => history.push("/transaksi/purchase-order")} />
      </Card.Header>
      <Card.Body>
        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        {isPageLoading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : isFetchingFailed ? (
          <DataStatus text="Data gagal dimuat, tidak dapat mengubah data!" />
        ) : (
          <PageContent />
        )}
      </Card.Body>
    </Card>
  );
};

export default UbahPurchaseOrder;
