import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from "formik"

const ModalFilter = ({ dropdown, modalConfig, setModalConfig, data, setData }) => {
  const formInitialValues = {
    tgl_seleksi_vendor_mulai: data?.filter?.tgl_seleksi_vendor_mulai,
    tgl_seleksi_vendor_selesai: data?.filter?.tgl_seleksi_vendor_selesai,
    tgl_purchase_order_mulai: data?.filter?.tgl_purchase_order_mulai,
    tgl_purchase_order_selesai: data?.filter?.tgl_purchase_order_selesai,
    tgl_pengiriman_mulai: data?.filter?.tgl_pengiriman_mulai,
    tgl_pengiriman_selesai: data?.filter?.tgl_pengiriman_selesai,
    vendor: data?.filter?.vendor,
    nama_item: data?.filter?.nama_item,
  }
  const formSubmitHandler = (values) => {
      const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

      if (checkActive) {
        setData({ 
            filter: {
                ...values,
                active: true
            },
            pagination: {
                ...data.pagination,
                page: 1
            }
        })
      } else { 
          setData({ 
              ...data,
              filter: {
                  ...values,
                  active: false
              }
          })
      }

      setModalConfig(prev => ({...prev, show: false}))
  }
  const onChangeSeleksiVendor = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_seleksi_vendor_mulai: startDate,
        tgl_seleksi_vendor_selesai: endDate,
    })
  }
  const onChangePurchaseOrder = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_purchase_order_mulai: startDate,
        tgl_purchase_order_selesai: endDate,
    })
  }
  const onChangePengiriman = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_pengiriman_mulai: startDate,
        tgl_pengiriman_selesai: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_seleksi_vendor_mulai: undefined,
      tgl_seleksi_vendor_selesai: undefined,
      tgl_purchase_order_mulai: undefined,
      tgl_purchase_order_selesai: undefined,
      tgl_pengiriman_mulai: undefined,
      tgl_pengiriman_selesai: undefined,
      vendor: undefined,
      nama_item: undefined,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (

      <Modal
        show={modalConfig.show && modalConfig.type === 'filter'}
        onHide={() => setModalConfig(prev => ({ ...prev, show: false }))}>
          
        <Modal.Header closeButton><b>Filter Data</b></Modal.Header>
        <Modal.Body>
          <DatePicker
            selectsRange
            label="Tgl. Seleksi Vendor"
            placeholderText="Pilih tanggal seleksi vendor"
            dateFormat="dd/MM/yyyy"
            startDate={values.tgl_seleksi_vendor_mulai ? new Date(values.tgl_seleksi_vendor_mulai) : ''}
            endDate={values.tgl_seleksi_vendor_selesai ? new Date(values.tgl_seleksi_vendor_selesai) : ''}
            onChange={(dates) => onChangeSeleksiVendor(dates, values, setValues)}
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Purchase Order"
            placeholderText="Pilih tanggal purchase order"
            dateFormat="dd/MM/yyyy"
            startDate={values.tgl_purchase_order_mulai ? new Date(values.tgl_purchase_order_mulai) : ''}
            endDate={values.tgl_purchase_order_selesai ? new Date(values.tgl_purchase_order_selesai) : ''}
            onChange={(dates) => onChangePurchaseOrder(dates, values, setValues)}
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Pengiriman"
            placeholderText="Pilih tanggal pengiriman"
            dateFormat="dd/MM/yyyy"
            startDate={values.tgl_pengiriman_mulai ? new Date(values.tgl_pengiriman_mulai) : ''}
            endDate={values.tgl_pengiriman_selesai ? new Date(values.tgl_pengiriman_selesai) : ''}
            onChange={(dates) => onChangePengiriman(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            key={values.vendor}
            label="Vendor"
            placeholder="Pilih vendor"
            // defaultValue={dropdown?.upah?.find(item => item.value === values.item_upah)}
            // option={dropdown.upah}
            onChange={val => setFieldValue('vendor', val.value)}
          />
          <SelectSearch
            key={values.nama_item}
            label="Nama Item"
            placeholder="Pilih nama item"
            // defaultValue={dropdown?.vendor?.find(item => item.value === values.vendor)}
            // option={dropdown.vendor}
            onChange={val => setFieldValue('nama_item', val.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => onResetButtonClick(values, setValues)}
          >
            RESET
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            FILTER
          </Button>
        </Modal.Footer>
      </Modal >

      )}
  </Formik>
  )
}

export default ModalFilter