import { Row, Col, Modal, Button } from 'react-bootstrap'
import { InfoItemVertical } from 'components'
import { InfoSectionModal } from '../InfoSection'
import { DateConvert, RupiahConvert } from 'utilities'

const ModalDetail = ({ modalConfig, setModalConfig }) => {
  const {
    tgl_transfer_produksi_subkon,
    no_transfer_produksi_subkon,
    nama_vendor,
    qty_transfer,
    nama_satuan,
    harga_satuan_subkon,
    keterangan_transfer
  } = modalConfig.data

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, type: 'detail', data: {} })}
    >
      <Modal.Header closeButton>
        <b>Detail Data Transfer Subkon Produksi</b>
      </Modal.Header>
      <Modal.Body>
        <InfoSectionModal data={modalConfig.data} />
        <Row>
          <Col>
            <InfoItemVertical
              label="Tgl. Transfer Subkon Produksi"
              text={DateConvert(new Date(tgl_transfer_produksi_subkon)).detail} />
          </Col>
          <Col>
            <InfoItemVertical
              label="No. Transfer Subkon Produksi"
              text={no_transfer_produksi_subkon} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical
              label="Vendor"
              text={nama_vendor} />
          </Col>
          <Col>
            <InfoItemVertical
              label="Qty. Transfer"
              text={qty_transfer ? `${parseFloat(qty_transfer)} ${nama_satuan}` : "-"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical
              label="Harga Satuan"
              text={RupiahConvert(parseInt(harga_satuan_subkon || 0).toString()).detail} 
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="Total Harga"
              text={RupiahConvert((parseInt(harga_satuan_subkon || 0) * parseFloat(qty_transfer || 0)).toString()).detail} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical
              label="Keterangan Transfer"
              text={keterangan_transfer} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => setModalConfig({ show: false, type: 'detail', data: {} })}
        >
          KEMBALI
        </Button>
      </Modal.Footer>
    </Modal >
  )
}

export default ModalDetail