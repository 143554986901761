import { useState, useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { Card, Row, Col } from "react-bootstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Axios from "axios"
import {
  BackButton,
  DatePicker,
  Input,
  DataStatus
} from "components"
import { TransferSubkonProduksiApi } from "api"
import {
  InfoSectionPermintaanProduksi,
  TablePermintaanProduksi,
  TableHistory
} from "./Section"

const TambahTransferSubkonProduksi = ({ setNavbarTitle }) => {
  const TODAY = new Date()
  const history = useHistory()
  const location = useLocation()
  const { id_permintaan_produksi } = useParams()
  const { pagination } = location.state
  const [isFetching, setIsFetching] = useState({ loading: false, success: false })
  const [dataInfo, setDataInfo] = useState({})
  const [listData, setListData] = useState({ data: [], history: [] })


  const getInitialData = () => {
    setIsFetching({ loading: true, success: false })

    Axios.all([
      TransferSubkonProduksiApi.getSingle({ id_permintaan_produksi }),
      TransferSubkonProduksiApi.getHistory({ id_permintaan_produksi })
    ])
      .then(Axios.spread((resSingle, resHistory) => {
        const { data = {} } = resSingle?.data
        const { data: dataHistory = [] } = resHistory?.data

        setDataInfo(data ?? {})
        setListData({
          data: data?.detail ?? [],
          history: dataHistory ?? [],
        })
        setIsFetching({ loading: false, success: true })
      }))
      .catch(() => {
        setIsFetching({ loading: false, success: false })
        window.alert("Gagal memuat data!")
      })
  }

  useEffect(() => {
    setNavbarTitle("Transfer Subkon Produksi")
    getInitialData()
  }, [])

  if (isFetching.loading || !isFetching.success) {
    return (
      <>
        <div className="mb-2 d-flex justify-content-end align-items-center">
          <BackButton
            onClick={() =>
              history.push("/transaksi/transfer-subkon-produksi/list-permintaan-produksi", { pagination })
            }
          />
        </div>
        <Card>
          <Card.Body>
            {isFetching.loading
              ? <DataStatus loading={isFetching} text="Memuat Data . . ." />
              : <DataStatus text="Tidak dapat memuat data!" />
            }
          </Card.Body>
        </Card>
      </>
    )
  }

  return (
    <>
      <div className="mb-2 d-flex justify-content-end align-items-center">
        <BackButton
          onClick={() => history.push("/transaksi/transfer-subkon-produksi/list-permintaan-produksi", { ...location?.state })}
        />
      </div>

      <div className="font-weight-bold mb-1">Tambah Data Permintaan Produksi</div>
      {/* Info Section  */}
      <Card>
        <Card.Body>
          <InfoSectionPermintaanProduksi data={dataInfo} />
        </Card.Body>
      </Card>

      <Card className="border mt-3">
        <Card.Body>
          <TablePermintaanProduksi
            type="create"
            data={listData?.data}
            setData={setListData} />

          <TableHistory data={listData.history} />
        </Card.Body>
      </Card>
    </>
  )
}

export default TambahTransferSubkonProduksi
