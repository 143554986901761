// React
import { useEffect, useState } from "react"

// API
import { HargaPerkiraanSendiriApi } from "api"

// Components
import { 
	ActionButton, Alert, CRUDLayout, DataStatus, ThFixed, Tr,
	NumberFormat, Pagination, TBody, Td, TdFixed, Th, THead, 
	UpdateButton, UpdateModal, InfoItemHorizontal , Table
} from "components"
import { Modal, ButtonGroup } from "react-bootstrap"
import {  DateConvert, RupiahConvert, TableNumber } from "utilities"

// Form
import { Formik } from "formik"
import * as Yup from 'yup'
import { AiOutlineEdit, AiOutlineHistory } from 'react-icons/ai'
import Thead from "components/Table/THead"
import Axios from "axios"
const TabItem = ({ q, filter, value }) => {
	// States
	const [dataFilter, setDataFilter] = useState({
		page: 1,
		per_page: 10
	})
	const [page, setPage] = useState({
		loading: false,
		status: false
	})
	const [alertConfig, setAlertConfig] = useState({
		show: false,
		variant: "primary",
		text: `Hasil Pencarian : ${q}`,
	})
	const [data, setData] = useState([])
	const [dataHistory, setDataHistory] = useState([])
	const [dataHistoryDetail, setDataHistoryDetail] = useState([])
  	const [totalData, setTotalData] = useState(0)
	const [totalPage, setTotalPage] = useState(1)
	const [modal, setModal] = useState({
		type: "",
		show: false,
		data: {}
	})

	const toggleModal = () => setModal({ ...modal, show: !modal.show })

	const mappingData = value => {
		const buaso = value => {
			switch (value) {
				case "1":
					return "B"
				case "2":
					return "U"
				case "3":
					return "A"
				case "4":
					return "S"
				case "5":
					return "O"
				default:
					return null
			}
		}
	
		return value.map(val => {
			return {
				...val,
				buaso: buaso(val.id_buaso)
			}
		})
	}

	const getData = () => {
		setPage({
			loading: true,
			status: false
		})

		Axios.all([
			HargaPerkiraanSendiriApi.page({
				...dataFilter,
				q,
				// id_buaso: Boolean(filter && filter !== "") ? filter : undefined
				id_buaso: 1
			}),
		])
		.then(Axios.spread((res) => {
			mappingData(res.data.data)
			setData(res.data.data)
			setTotalPage(res.data.total_page)
			setTotalData(res.data.data_count)

			setPage({
				loading: false,
				status: true
			})
		}))
		.catch(() => {
			setPage({
				loading: false,
				status: false
			})
		})
	}
	// ON COMPONENT MOUNT
	useEffect(() => {
		getData() // GET DATA DARI SERVER

		// eslint-disable-next-line
	}, [q, filter, dataFilter])

	const DataModal = () => {
		const List = ({ label, children }) => (
			<Tr>
				<Td className='text-nowrap text-capitalize'>{label}</Td>
				<Td className='mx-50'>:</Td>
				<Td>{children}</Td>
			</Tr>
		)

		return (
			<UpdateModal 
				show={modal.type === "update" && modal.show} 
				onHide={toggleModal} 
				title="Harga Satuan Rata-Rata"
			>
				<Formik 
					enableReinitialize
					initialValues={{
						id_item_buaso: modal?.data?.id_item_buaso,
						harga_satuan_rata_rata: modal?.data?.harga_satuan_rata_rata ? parseFloat(modal?.data?.harga_satuan_rata_rata) : "",
						// kode_buaso: modal.data.kode_item,
						// id_item_hps: modal.data.id_item,
						// hps_beli: parseFloat(modal.data.hps_beli),
						// hps_pakai: parseFloat(modal.data.hps_pakai),
						// buaso: modal.data.buaso,
						// tgl_harga_perkiraan_sendiri: new Date(),
						// tgl_hps_beli: modal.data.tgl_hps_beli ?? new Date(),
						// tgl_hps_pakai: modal.data.tgl_hps_pakai ?? new Date(),
						// hps: modal.data[`hps_${value}`] ? parseFloat(modal.data[`hps_${value}`]) : ""
					}}
					validationSchema={Yup.object().shape({
						harga_satuan_rata_rata: Yup.string().required(`Harga satuan rata-rata wajib diisi`).nullable()
					})}
					onSubmit={(values, { setSubmitting }) => {
						const finalValues = {
							id_item_buaso: modal?.data?.id_item_buaso,
							harga_satuan_rata_rata: modal?.data?.harga_satuan_rata_rata,
							...values,
						};
						HargaPerkiraanSendiriApi.update(
							finalValues
						// 	{
						// 	...values,
						// 	id_item_buaso: modal?.data?.id_item_buaso
						// 	// buaso: modal?.data?.grup_buaso ? modal.data.grup_buaso.split('')[0] : undefined,
						// 	// [`hps_${value}`]: values.hps
						// }
						).then(() => {
							setAlertConfig({
								show: true,
								variant: "primary",
								text: "Ubah data berhasil!"
							})
						}).catch(() => {
							setAlertConfig({
								show: true,
								variant: "danger",
								text: "Ubah data gagal!"
							})
						}).finally(() => {
							toggleModal()
							setSubmitting(false)
							getData()
						})
					}}
				>
					{({ values, errors, touched, isSubmitting, setFieldValue, handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Modal.Body>
								<table>
									<TBody>
										<List label='Kode Item'>{modal.data.kode_item ?? "-"}</List>
										<List label='Nama Item'>{modal.data.nama_item ?? "-"}</List>
										<List label='Grup BUASO'>{modal.data.nama_buaso ?? "-"}</List>
										{/* <List label={`Satuan ${value}`}>{modal.data[`satuan_${value}`] ?? "-"}</List> */}
										<List label='Satuan'>{modal.data.nama_satuan ?? "-"}</List>
										<List label='Tgl. Update Terakhir'>{modal?.data?.last_update ? DateConvert(new Date(modal?.data?.last_update)).defaultDMYWithTime : "-"}</List>
									</TBody>
								</table>
								<hr />

								<NumberFormat
									// label={`Harga Perkiraan Sendiri Per Satuan ${value}`}
									label="Harga Satuan Rata-Rata"
									value={values.harga_satuan_rata_rata}
									defaultValue={values.harga_satuan_rata_rata}
									onChange={value => setFieldValue('harga_satuan_rata_rata', value ?? "")}
									decimalScale={2}
									prefix="Rp"
									className='text-right'
									error={Boolean(errors.harga_satuan_rata_rata && touched.harga_satuan_rata_rata) && true}
									errorText={errors.harga_satuan_rata_rata}
								/>
							</Modal.Body>

							<Modal.Footer>
								<ActionButton
									type="submit"
									variant="success"
									text="Ubah"
									className="mt-2 px-4"
									loading={isSubmitting}
								/>
							</Modal.Footer>
						</form>
					)}
				</Formik>
			</UpdateModal>
		)
	}
	const HistoryModal = () => {
		const [dataHistory, setDataHistory] = useState([])
		const [dataHistoryDetail, setDataHistoryDetail] = useState([])
		const [loading, setLoading] = useState(false)
		console.log("dataDetail", dataHistoryDetail)
		
		const getHistory = () => {
			setLoading(true)
			HargaPerkiraanSendiriApi.historyPage({ id_item_buaso: modal?.data?.id_item_buaso })
			.then(read => {
				console.log("read", read)
				setDataHistory(read?.data?.data ?? [])
				setDataHistoryDetail(read?.data?.data?.history ?? [])
				setLoading(false)
			})
			.catch(() => window.alert("Data gagal dimuat!"))
		}
		useEffect(() => {
			getHistory()
		}, [])
		return (
			<Modal 
				show={modal.type === "history" && modal.show}
				onHide={toggleModal} 
			>
				<Modal.Header closeButton>
					<Modal.Title className="text-primary">
						<h6 className="mb-0">
							<AiOutlineHistory className="mb-1 mr-2" size={20} />
							History Item Barang
						</h6>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{loading 
						? <DataStatus loading={true} text="Memuat Data..." />
						: <>
							<table>
							<TBody>
								<InfoItemHorizontal width={90} label='Kode Item' text={dataHistory?.kode_item ?? "-"} />
								<InfoItemHorizontal width={90} label='Nama Item' text={dataHistory?.nama_item ?? "-" } />
								<InfoItemHorizontal width={90} label='Grup BUASO' text={dataHistory?.nama_buaso ?? "-" } />
								<InfoItemHorizontal width={90} label='Satuan' text={dataHistory?.nama_satuan ?? "-" } />
							</TBody>
							</table>
							<hr />
							<Table>
								<Thead>
									<Tr>
										<ThFixed>No.</ThFixed>
										<Th>Tanggal Update Terakhir</Th>
										<Th>Harga Satuan Rata-Rata</Th>
									</Tr>
								</Thead>
								<TBody>
									{dataHistoryDetail?.map((val, index) => {
										return (
											<Tr>
												<TdFixed>{index +1}</TdFixed>
												<Td>{val.updated_at ? DateConvert(new Date(val.updated_at)).defaultDMYWithTime : "-"}</Td>
												<Td textRight>{val.harga_satuan_rata_rata ? RupiahConvert(String(parseFloat(val.harga_satuan_rata_rata))).getWithComa : "Rp.0"}</Td>
											</Tr>
										)
									})}
								</TBody>
							</Table>
						  </>
					}
				</Modal.Body>
			</Modal>
		)
	}

	// TABLE COMPONENT
	const TableSection = () => {
		return (
		<>
			<CRUDLayout.Table>
				<THead>
					<Tr className="text-center">
						<ThFixed>No.</ThFixed>
						<ThFixed>Kode Item</ThFixed>
						<Th>Nama Item</Th>
						<ThFixed>Grup BUASO</ThFixed>
						{/* <Th className="text-capitalize" style={{ width: '100px' }}>Satuan {value === 'beli' ? 'Beli' : 'Pakai'}</Th> */}
						<Th>Satuan</Th>
						{/* <Th className="text-capitalize" style={{ width: '150px' }}>HPS {value === 'beli' ? 'Beli' : 'Pakai'}</Th> */}
						<Th>Harga Satuan Rata-Rata</Th>
						<ThFixed>Tgl. Update Terakhir</ThFixed>
						<ThFixed>Aksi</ThFixed>
					</Tr>
				</THead>
				<TBody>
					{data.map((val, index) => {
						return (
							<Tr key={index}>
								<TdFixed>{TableNumber(dataFilter.page, dataFilter.per_page, index)}</TdFixed>
								<TdFixed className="text-center">{val.kode_item ? val.kode_item : "-"}</TdFixed>
								<Td>{val.nama_item ? val.nama_item : "-"}</Td>
								<Td>{val.nama_buaso ? val.nama_buaso : "-"}</Td>
								<Td>{val.nama_satuan ? val.nama_satuan : "-"}</Td>
								<Td className="text-right text-nowrap" style={{width: 200}}>{val.harga_satuan_rata_rata ? RupiahConvert(String(parseFloat(val.harga_satuan_rata_rata))).getWithComa : "Rp 0"}</Td>
								<Td style={{width: 150}}>{val.last_update ? DateConvert(new Date(val.last_update)).defaultDMYWithTime : "-"}</Td>
								<TdFixed>
									<ButtonGroup>
										<ActionButton 
											size="sm"
											variant="success"
											tooltip={true}
											tooltipText="Ubah Data"
											onClick={() => { setModal({ type: "update", show: true, data: val })}}>
											<AiOutlineEdit style={{ fontSize: '.9rem' }} />
										</ActionButton>
										<ActionButton 
											size="sm"
											variant="primary"
											tooltip={true}
											tooltipText="History"
											onClick={() => setModal({ type: "history", show: true, data: val })}>
											<AiOutlineHistory style={{ fontSize: '.9rem' }} />
										</ActionButton>
									</ButtonGroup>
								</TdFixed>
							</Tr>
						)
					})}
				</TBody>
			</CRUDLayout.Table>
			<Pagination
				dataLength={dataFilter.per_page}
				dataPage={
					totalData <= 10 ? data.length : data.map((_res, index) => {
						if (index === data.length - 1) {
							return TableNumber(dataFilter.page, dataFilter.per_page, index)
						} else {
							return null
						}
					})
				}
				dataNumber={data.map((_res, index) => {
					if (index === 0) {
						return TableNumber(dataFilter.page, dataFilter.per_page, index)
					} else {
						return null
					}
				})}
				dataCount={totalData}
				onDataLengthChange={(e) => {
					setDataFilter({
						...dataFilter,
						per_page: e.target.value,
						page: 1
					})
				}}
				currentPage={dataFilter.page}
				totalPage={totalPage}
				onPaginationChange={({ selected }) => setDataFilter({
					...dataFilter,
					page: selected + 1
				})}
			/>
		</>
		)
	}

	return (
		<>
			<Alert
				show={alertConfig.show}
				showCloseButton={true}
				variant={alertConfig.variant}
				text={alertConfig.text}
				onClose={() => setAlertConfig({
					...alertConfig,
					show: false
				})}
      		/>

			{page.loading || !page.status || data.length === 0 ? (
				<DataStatus loading={page.loading} text={page.loading ? "Memuat data..." : !page.status ? "Data gagal dimuat, tidak dapat menampilkan data" : "Tidak ada data"} />
			) : (
				<TableSection />
			)}

			{modal.type === "update" && <DataModal />}
			{modal.type === "history" && <HistoryModal />}
		</>
	)
}

export default TabItem