import React, { useState, memo } from "react"
import { IoAddOutline, IoCheckmarkOutline } from "react-icons/io5"
import { DataStatus, Table, Th, ThFixed, Td, ActionButton } from "components"
import { ModalForm } from "../../Section"

const ItemRow = memo(({ modalConfig, setModalConfig, index, val }) => {
  const tableStyling = { width: 120, padding: 0, fontSize: "14px" }

  // check jika qty telah transfer lebih besar samadengan qty permintaan produksi, 
  // maka tidak boleh menambah data transfer
  const isPreventCreate = Boolean(parseInt(val?.qty_telah_ditransfer ?? 0) >= parseInt(val?.qty_permintaan_produksi ?? 0))

  return (
    <tr>
      <Td textCenter width={20}> {index + 1} </Td>
      <Td>{val.kode_item}</Td>
      <Td>{val.nama_item}</Td>
      <Td>{val.nama_satuan}</Td>
      <Td style={tableStyling} className="text-right">{parseFloat(val.qty_permintaan_produksi ?? 0)}</Td>
      <Td style={tableStyling} className="text-right">{parseFloat(val.qty_telah_ditransfer ?? 0)}</Td>
      <Td>
        {isPreventCreate
          ? (
            <ActionButton disable size="sm" variant="success" >
              <IoCheckmarkOutline />
            </ActionButton>
          )
          : (
            <ActionButton size="sm" onClick={() => setModalConfig({ show: true, data: val })} >
              <IoAddOutline />
            </ActionButton>
          )
        }
      </Td>
    </tr>
  )
})

const TablePermintaanProduksi = ({ data, setData }) => {
  const [modalConfig, setModalConfig] = useState({ show: false, data: {} })

  return (
    <React.Fragment>
      <small className="h6 font-weight-bold">List Data Permintaan Produksi</small>
      <div className="table-responsive">
        <Table>
          <thead className="text-center bg-light">
            <tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Kode Item</ThFixed>
              <Th>Item Upah </Th>
              <ThFixed>Satuan</ThFixed>
              <Th width={100}>Qty. Permintaan Produksi</Th>
              <Th width={100}>Total Qty. Telah Ditransfer</Th>
              <ThFixed>Aksi</ThFixed>
            </tr>
          </thead>
          <tbody>
            {data?.map((val, index) => (
              <ItemRow
                key={index}
                index={index}
                val={val}
                data={data}
                setData={setData}
                modalConfig={modalConfig}
                setModalConfig={setModalConfig}
              />
            ))}

            {/* Handle tidak ada data */}
            {data?.length === 0 && (
              <tr>
                <Td colSpan={7} textCenter>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {modalConfig.show && (
        <ModalForm
          modalConfig={modalConfig}
          setModalConfig={setModalConfig} />
      )}
    </React.Fragment>
  )
}

export default TablePermintaanProduksi
