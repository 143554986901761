import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from 'formik'

const ModalFilterTransfer = ({ dropdown, modalConfig, setModalConfig, data, setData }) => {
  const formInitialValues = {
    tgl_transfer_bahan_produksi_mulai: data?.filter?.tgl_transfer_bahan_produksi_mulai,
    tgl_transfer_bahan_produksi_selesai: data?.filter?.tgl_transfer_bahan_produksi_selesai,
    tgl_permintaan_produksi_mulai: data?.filter?.tgl_permintaan_produksi_mulai,
    tgl_permintaan_produksi_selesai: data?.filter?.tgl_permintaan_produksi_selesai,
    item_subkon: data?.filter?.item_subkon,
    vendor: data?.filter?.vendor
  }
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData({ 
          filter: {
              ...values,
              active: true
          },
          pagination: {
              ...data.pagination,
              page: 1
          }
      })
    } else { 
        setData({ 
            ...data,
            filter: {
                ...values,
                active: false
            }
        })
    }

    setModalConfig(prev => ({...prev, show: false}))
  }
  const onChangeTransferProduksi = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_transfer_bahan_produksi_mulai: startDate,
        tgl_transfer_bahan_produksi_selesai: endDate,
    })
  }
  const onChangePermintaanProduksi = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_permintaan_produksi_mulai: startDate,
        tgl_permintaan_produksi_selesai: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_transfer_bahan_produksi_mulai: undefined,
      tgl_transfer_bahan_produksi_selesai: undefined,
      tgl_permintaan_produksi_mulai: undefined,
      tgl_permintaan_produksi_selesai: undefined,
      id_item_subkon: undefined,
      id_vendor: undefined
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (
        <Modal
          show={modalConfig.show && modalConfig.type === 'filter'}
          onHide={() => setModalConfig(prev => ({ ...prev, show: false }))}
        >
          <Modal.Header closeButton>
            <b>Filter Data</b>
          </Modal.Header>
          <Modal.Body>
            <DatePicker
              selectsRange
              label="Tgl. Transfer Produksi"
              placeholderText="Pilih tanggal transfer produksi"
              startDate={values.tgl_transfer_bahan_produksi_mulai ? new Date(values.tgl_transfer_bahan_produksi_mulai) : ''}
              endDate={values.tgl_transfer_bahan_produksi_selesai ? new Date(values.tgl_transfer_bahan_produksi_selesai) : ''}
              onChange={(dates) => onChangeTransferProduksi(dates, values, setValues)}
              monthsShown={2}
            />
            <DatePicker
              selectsRange
              label="Tgl. Permintaan Produksi"
              placeholderText="Pilih tanggal permintaan produksi"
              startDate={values.tgl_permintaan_produksi_mulai ? new Date(values.tgl_permintaan_produksi_mulai) : ''}
              endDate={values.tgl_permintaan_produksi_selesai ? new Date(values.tgl_permintaan_produksi_selesai) : ''}
              onChange={(dates) => onChangePermintaanProduksi(dates, values, setValues)}
              monthsShown={2}
            />
            <SelectSearch
              key={values.item_subkon}
              label="Item Subkon"
              placeholder="Pilih item subkon"
              defaultValue={dropdown?.subkon?.find(item => item.value === values.item_subkon)}
              option={dropdown.subkon}
              onChange={val => setFieldValue('item_subkon', val.value)}
            />
            <SelectSearch
              key={values.vendor}
              label="Vendor"
              placeholder="Pilih salah satu"
              defaultValue={dropdown?.vendor?.find(item => item.value === values.vendor)}
              option={dropdown.vendor}
              onChange={val => setFieldValue('vendor', val.value)}
            />
          </Modal.Body>
          <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => onResetButtonClick(values, setValues)}
          >
            RESET
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            FILTER
          </Button>
          </Modal.Footer>
        </Modal >
      )}
    </Formik>
  )
}

export default ModalFilterTransfer