// React
import React, { useState, useEffect } from "react";

// Router
import { useHistory, useParams } from "react-router-dom";

// Component
import {
  CRUDLayout,
  DeleteButton,
  DataStatus,
  THead,
  TBody,
  Alert,
  TdFixed,
  Tr,
  Th,
  Td,
  Input,
  ActionButton,
} from "../../../components";
import { Button, Form, Row, Col } from "react-bootstrap";

// Form
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// API
import Axios from "axios";
import {
  PrakualifikasiVendorApi,
  VendorApi,
  ItemBahanApi,
  ItemUpahApi,
  ItemSubconApi,
} from "../../../api";

// Icon
import { IoArrowBackOutline } from "react-icons/io5";

const TambahPrakualifikasiVendor = ({ setNavbarTitle }) => {
  let history = useHistory();
  let { id } = useParams();

  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(false);

  // Alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    status: false,
  });

  // Data View
  const [data, setData] = useState({
    jenis_vendor: [],
  });
  const [dataNo, setDataNo] = useState("");
  const [dataBahan, setDataBahan] = useState([]);
  const [dataUpah, setDataUpah] = useState([]);
  const [dataSubcon, setDataSubcon] = useState([]);
  const [dokumenLain, setDokumenLain] = useState("");
  const [dataDokumenLain, setDataDokumenLain] = useState([]);
  const dataDokumen = [
    "Copy NPWP",
    "Copy KTP",
    "Copy SIUP",
    "Copy Tanda Daftar Perusahaan",
    "Kemampuan Modal Usaha",
    "Referensi Bank",
    "Sertifikat Produk/Sertiikat Sistem Mutu",
    "Surat Penunjukan Keagenan",
  ];

  const [documentLainLoading, setDocumentLainLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);

    Axios.all([
      VendorApi.single(id),
      PrakualifikasiVendorApi.getNoBaru(id),
      ItemBahanApi.get(id),
      ItemUpahApi.get(id),
      ItemSubconApi.get(id),
    ]).then(
      Axios.spread((res, no_baru, bahan, upah, subcon) => {
        setData(res.data.data);
        setDataNo(no_baru.data.data);
        setDataBahan(bahan.data.data);
        setDataUpah(upah.data.data);
        setDataSubcon(subcon.data.data);
        setIsLoading(false);
      })
    );
  };

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle("Tambah Data Prakualifikasi Vendor");

    // jalankan function request data ke server
    getData();

    // menangani pembersihan saat fetching data
    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  const ShowData = ({ title, text }) => (
    <div className="d-flex flex-row">
      <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
        {title}
      </div>
      <span>:</span>
      <div
        className="text-dark"
        style={{
          paddingLeft: 5,
        }}
      >
        {text ? text : "-"}
      </div>
    </div>
  );

  const array_jumlah_item = dataBahan.map((val) => {
    return {
      id_item_buaso: val.id_item_bahan,
      jumlah_item: 0,
    };
  });

  const formInitialValues = {
    id_vendor: data.id_vendor,
    tgl_vendor_prakualifikasi: "",
    status_hukum: true,
    jumlah_karyawan: 0,
    lama_menyediakan_item: "1",
    jumlah_item: array_jumlah_item,
    dokumen_utama: [],
    no_prakualifikasi: dataNo,
  };

  const formValidationSchema = Yup.object().shape({
    tgl_vendor_prakualifikasi: Yup.string().required("Tanggal Vendor Prakualifikasi wajib diisi"),
    status_hukum: Yup.string().required("Status Hukum wajib diisi"),
    jumlah_karyawan: Yup.number().required("Jumlah karyawan wajib diisi"),
    lama_menyediakan_item: Yup.string().required("Tanggal Vendor Prakualifikasi wajib diisi"),
  });

  const formSubmitHandler = (values) => {
    values.dokumen_utama.map((val, index) => {
      val.id_vendor_prakualifikasi_dokumen = index + 1;
    });

    const finalValues = {
      ...values,
      dokumen_lainnya: dataDokumenLain,
    };

    PrakualifikasiVendorApi.create(finalValues)
      .then(() => {
        history.push("/master/prakualifikasi-vendor", {
          variant: "primary",
          text: "Tambah Prakualifikasi Vendor berhasil",
          status: true,
        });
      })
      .catch((err) =>
        setAlertConfig({
          variant: "danger",
          text: `Tambah Prakualifikasi Vendor gagal! (${err})`,
        })
      );
  };

  return (
    <>
      <div className="row mx-1 mb-2 justify-content-between">
        <h4>Data Prakualifikasi Vendor</h4>

        <Button
          variant="outline-secondary"
          size="md"
          onClick={() => history.push("/master/prakualifikasi-vendor")}
        >
          <IoArrowBackOutline size={20} />
          <span className="ml-2">Kembali Ke Tabel</span>
        </Button>
      </div>

      <div className="mb-3 p-3 bg-white rounded shadow-sm">
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
        {isLoading == true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : (
          <>
            <Formik
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleSubmit,
              }) => (
                <form className="py-2" onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="6">
                      <Input
                        label="Tanggal Prakualifikasi"
                        type="date"
                        name="tgl_vendor_prakualifikasi"
                        value={values.tgl_vendor_prakualifikasi}
                        error={
                          errors.tgl_vendor_prakualifikasi &&
                          touched.tgl_vendor_prakualifikasi &&
                          true
                        }
                        errorText={errors.tgl_vendor_prakualifikasi}
                        onChange={(e) => {
                          setFieldValue("tgl_vendor_prakualifikasi", e.target.value);

                          PrakualifikasiVendorApi.getNoBaru(e.target.value).then((res) =>
                            setFieldValue("no_prakualifikasi", res.data.data)
                          );
                        }}
                      />
                    </Col>
                    <Col lg="6">
                      <Input
                        label="No Prakualifikasi"
                        value={values.no_prakualifikasi}
                        readOnly={true}
                      />
                    </Col>
                  </Row>

                  <div className="mb-2">
                    <small>Status Hukum Vendor</small>
                    <br></br>
                    <Form.Check
                      type="radio"
                      label="Berbadan Hukum"
                      name="status_hukum"
                      value={true}
                      error={errors.status_hukum && touched.status_hukum && true}
                      errorText={errors.status_hukum}
                      onChange={handleChange}
                      className="d-inline mx-1"
                      required
                    />
                    <Form.Check
                      type="radio"
                      label="Tidak Berbadan Hukum"
                      name="status_hukum"
                      value={false}
                      error={errors.status_hukum && touched.status_hukum && true}
                      errorText={errors.status_hukum}
                      onChange={handleChange}
                      className="d-inline mx-1"
                      required
                    />
                    <ErrorMessage
                      name="status_hukum"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <Row>
                    <Col lg="6">
                      <div className="mb-2">
                        <ShowData title="Kode Vendor" text={data.kode_vendor} />

                        <ShowData title="Nama Vendor" text={data.nama_vendor} />

                        <ShowData
                          title="Jenis Vendor"
                          text={data.jenis_vendor.map((val) => val.jenis_vendor).join(", ")}
                        />

                        <ShowData
                          title="Pimpinan Perusahaan"
                          text={data.nama_pimpinan_perusahaan}
                        />
                      </div>

                      <div className="mb-2">
                        <ShowData title="Contact Person" text={data.contact_person} />

                        <ShowData title="Telepon" text={data.telepon} />

                        <ShowData title="Email" text={data.email} />

                        <ShowData title="Website" text={data.website} />

                        <ShowData title="Media Sosial" text={data.media_sosial} />
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="mb-2">
                        <ShowData title="Alamat Vendor" text={data.alamat_vendor} />

                        <ShowData title="Desa" text={data.nama_desa} />

                        <ShowData title="Kecamatan" text={data.nama_kecamatan} />

                        <ShowData title="Kabupaten" text={data.nama_kabupaten} />

                        <ShowData title="Provinsi" text={data.nama_provinsi} />

                        <ShowData title="Kode Pos" text={data.kode_pos} />
                      </div>
                    </Col>
                  </Row>

                  {data.jenis_vendor.map((value) => {
                    if (value.jenis_vendor == "Mandor") {
                      return (
                        <>
                          <small>List Item Upah Yang Disediakan Vendor Ini Sebagai Mandor</small>
                          <CRUDLayout.Table>
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Kode Item Bahan</th>
                                <th>Nama Item Bahan</th>
                                <th>Satuan Pakai</th>
                              </tr>
                            </thead>

                            <tbody>
                              {dataUpah.length > 0 ? (
                                dataUpah.map((val, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{val.kode_item}</td>
                                    <td>{val.nama_item}</td>
                                    <td>{val.nama_satuan}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="3" className="my-2 font-weight-bold text-center">
                                    Tidak Ada Data
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </CRUDLayout.Table>
                        </>
                      );
                    } else if (value.jenis_vendor == "Subcon") {
                      return (
                        <>
                          <small>Jumlah Item Bahan Yang Akan Disediakan</small>
                          <CRUDLayout.Table>
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Kode Item Subcon</th>
                                <th>Nama Item Subcon</th>
                                <th>Kelompok Subcon</th>
                                <th>Satuan Pakai</th>
                              </tr>
                            </thead>

                            <tbody>
                              {dataSubcon.length > 0 ? (
                                dataSubcon.map((val, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{val.kode_item}</td>
                                    <td>{val.nama_item}</td>
                                    <td>{val.nama_kelompok}</td>
                                    <td>{val.nama_satuan}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="5" className="my-2 font-weight-bold text-center">
                                    Tidak Ada Data
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </CRUDLayout.Table>
                        </>
                      );
                    }
                  })}

                  <Row>
                    <Col lg="6">
                      <Input
                        label="Jumlah Karyawan"
                        name="jumlah_karyawan"
                        value={values.jumlah_karyawan}
                        error={errors.jumlah_karyawan && touched.jumlah_karyawan && true}
                        errorText={errors.jumlah_karyawan}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>

                  {data.jenis_vendor.map((value) => {
                    if (value.jenis_vendor == "Supplier") {
                      return (
                        <>
                          <small>List Item Bahan Yang Disediakan Vendor Ini Sebagai Supplier</small>
                          <CRUDLayout.Table>
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Kode Item Bahan</th>
                                <th>Nama Item Bahan</th>
                                <th>Satuan</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>

                            <tbody>
                              {dataBahan.length > 0 ? (
                                dataBahan.map((val, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{val.kode_item}</td>
                                    <td>{val.nama_item}</td>
                                    <td>{val.satuan_pakai}</td>
                                    <td>
                                      <Input
                                        name={"jumlah_item[" + index + "][jumlah_item]"}
                                        type="number"
                                        required
                                        min="0"
                                        onChange={handleChange}
                                      />
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="6" className="my-2 font-weight-bold text-center">
                                    Tidak Ada Data
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </CRUDLayout.Table>
                        </>
                      );
                    }
                  })}

                  <div className="mb-2">
                    <small>Lama Vendor Menyediakan Item</small>
                    <br></br>
                    <Form.Check
                      type="radio"
                      label="Kurang Dari 1 (Satu) Tahun"
                      name="lama_menyediakan_item"
                      value="1"
                      onChange={handleChange}
                      required
                    />
                    <Form.Check
                      type="radio"
                      label="Antara 1 (Satu) Sampai 3 (Tiga) Tahun"
                      name="lama_menyediakan_item"
                      value="2"
                      onChange={handleChange}
                      required
                    />
                    <Form.Check
                      type="radio"
                      label="Diatas 3 (Tiga) Tahun"
                      name="lama_menyediakan_item"
                      value="3"
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <small>Kelengkapan Dokumen</small>
                  <CRUDLayout.Table>
                    <THead>
                      <Tr>
                        <Th>No</Th>
                        <Th>Uraian</Th>
                        <Th>Ada</Th>
                        <Th>Tidak Ada</Th>
                      </Tr>
                    </THead>

                    <TBody>
                      {dataDokumen.map((val, index) => {
                        return (
                          <Tr key={index}>
                            <TdFixed>{index + 1}</TdFixed>
                            <Td>{val}</Td>
                            <TdFixed>
                              <Form.Check
                                type="radio"
                                label=""
                                name={"dokumen_utama[" + index + "][ada_atau_tidak]"}
                                value={true}
                                required
                                onChange={(value) =>
                                  setFieldValue(
                                    "dokumen_utama[" + index + "][ada_atau_tidak]",
                                    value.target.value
                                  )
                                }
                              />
                            </TdFixed>
                            <TdFixed>
                              <Form.Check
                                type="radio"
                                label=""
                                name={"dokumen_utama[" + index + "][ada_atau_tidak]"}
                                value={false}
                                required
                                onChange={(value) =>
                                  setFieldValue(
                                    "dokumen_utama[" + index + "][ada_atau_tidak]",
                                    value.target.value
                                  )
                                }
                              />
                            </TdFixed>
                          </Tr>
                        );
                      })}
                    </TBody>
                  </CRUDLayout.Table>

                  <small>Dokumen Lainnya (Sebutkan)</small>
                  <Row>
                    <Col sm="9">
                      <Input
                        label=""
                        onChange={(val) => {
                          setDokumenLain(val.target.value);
                        }}
                      />
                    </Col>
                    <Col sm="3">
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          if (dokumenLain != "") {
                            setDataDokumenLain([...dataDokumenLain, dokumenLain]);
                            setDokumenLain("");
                          }
                        }}
                      >
                        Tambah
                      </Button>
                    </Col>
                  </Row>

                  <CRUDLayout.Table>
                    <THead>
                      <Tr>
                        <Th>No</Th>
                        <Th>Uraian</Th>
                        <Th></Th>
                      </Tr>
                    </THead>
                    <TBody>
                      {dataDokumenLain.length > 0 ? (
                        documentLainLoading === true ? (
                          <DataStatus text="Loading..." />
                        ) : (
                          dataDokumenLain.map((val, index) => {
                            return (
                              <Tr key={index}>
                                <TdFixed>{index + 1}</TdFixed>
                                <Td>{val}</Td>
                                <TdFixed>
                                  <DeleteButton
                                    onClick={() => {
                                      setDocumentLainLoading(true);
                                      dataDokumenLain.splice(index, 1).push(dataDokumenLain);
                                      console.log(dataDokumenLain);
                                      setTimeout(() => {
                                        setDocumentLainLoading(false);
                                      }, 0);

                                      // setDataDokumenLain(array);
                                    }}
                                  />
                                </TdFixed>
                              </Tr>
                            );
                          })
                        )
                      ) : (
                        <tr>
                          <td colSpan="3" className="my-2 font-weight-bold text-center">
                            Tidak Ada Data
                          </td>
                        </tr>
                      )}
                    </TBody>
                  </CRUDLayout.Table>

                  <div className="d-flex justify-content-end pt-3">
                    <ActionButton
                      type="submit"
                      variant="primary"
                      text="Simpan"
                      loading={isSubmitting}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
    </>
  );
};

export default TambahPrakualifikasiVendor;
