import React from 'react'
import { InfoItemHorizontal, InfoItemVertical } from 'components'

const InfoSectionModal = ({ data }) => {
  return (
    <div>
      <InfoItemHorizontal label="Kode Item" text={data?.kode_item ?? data?.kode_item_buaso ?? ""} />
      <InfoItemHorizontal label="Item Subkon" text={data?.nama_item ?? data?.nama_item_buaso ?? ""} />
      <InfoItemHorizontal label="Satuan" text={data?.nama_satuan ?? ""} />
      <InfoItemHorizontal label="Qty. Permintaan Produksi" text={data?.qty_permintaan_produksi ? parseFloat(data.qty_permintaan_produksi) : ""} />
      <InfoItemHorizontal label="Total Qty. Telah Ditransfer" text={data?.qty_telah_ditransfer ? parseFloat(data.qty_telah_ditransfer) : "0"} />
      <hr />
    </div>
  )
}

export default InfoSectionModal