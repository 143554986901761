import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  FilterButton,
  ReadButton
} from "../../../../components";
import { TableNumber, DateConvert } from "../../../../utilities";
import { SeleksiVendorApi } from "../../../../api";
import ModalFilter from "./ModalFilter";
import { AiOutlinePlus } from "react-icons/ai"
import Axios from "axios";

const ListPurchaseRequestSeleksiVendor = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPurchaseRequest, setDataPurchaseRequest] = useState([]);
  const [dropdownItem, setDropdownItem] = useState([{ label: "Semua", value: undefined }])
  const [processedData, setProcessedData] = useState({});

  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.transfer?.filter?.active,
      tgl_purchase_request_mulai: location?.state?.transfer?.filter?.tgl_purchase_request_mulai,
      tgl_purchase_request_selesai: location?.state?.transfer?.filter?.tgl_purchase_request_selesai,
      tgl_pemakaian_mulai: location?.state?.transfer?.filter?.tgl_pemakaian_mulai,
      tgl_pemakaian_selesai: location?.state?.transfer?.filter?.tgl_pemakaian_selesai,
      keperluan: location?.state?.transfer?.filter?.keperluan,
      nama_item: location?.state?.transfer?.filter?.nama_item,
    },
    pagination: {
      page: location?.state?.transfer?.filter?.page ?? "1",
      dataLength: location?.state?.transfer?.filter?.dataLength ?? "10",
      totalPage: location?.state?.transfer?.filter?.totalPage ?? "1",
      dataCount: location?.state?.transfer?.filter?.dataCount ?? "0",
    }
  })

  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "detail",
  });
  const [filterConfig, setFilterConfig] = useState({
    show: false,
    type: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    setAlertConfig({ ...alertConfig, show: false });

    Axios.all([
      SeleksiVendorApi.getPurchaseRequest({
        q: searchConfig.key,
        page: dataFilter?.pagination?.page,
        per_page: dataFilter?.pagination?.dataLength,
        tgl_purchase_request_mulai: dataFilter?.filter?.tgl_purchase_request_mulai,
        tgl_purchase_request_selesai: dataFilter?.filter?.tgl_purchase_request_selesai,
        tgl_pemakaian_mulai: dataFilter?.filter?.tgl_pemakaian_mulai,
        tgl_pemakaian_selesai: dataFilter?.filter?.tgl_pemakaian_selesai,
        keperluan: dataFilter?.filter?.keperluan,
        nama_item: dataFilter?.filter?.nama_item,
      }),
      SeleksiVendorApi.getDropdownItem()
    ])
      .then(Axios.spread(( data, item ) => {
        const mapItem = item?.data?.data ? item.data.data.map(val => ({ label: val.nama_item, value: val.id_item_buaso })) : []

        setDataPurchaseRequest(data.data.data);
        setDropdownItem([ ...dropdownItem, ...mapItem ])
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          }
        })
      }))
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => {
        setIsPageLoading(false);
        if (searchConfig.key != "") {
          setAlertConfig({
            show: true,
            variant: "primary",
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
        }
      });
  };

  const onSubmitSearchHandler = (e) => {
    e.preventDefault();
    setSearchConfig({
      ...searchConfig,
      status: true,
    });
    getInitialData();
  };

  const onResetHandler = () => {
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    })
    setSearchConfig({
      show: false,
      key: "",
    });
    setAlertConfig({
      ...alertConfig,
      show: false,
    });
  };

  useEffect(() => {
    setNavbarTitle("Seleksi Vendor");
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.status,
    searchConfig.key,
    dataFilter?.pagination?.page, 
    dataFilter?.pagination?.dataLength, 
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_purchase_request_mulai,
    dataFilter?.filter?.tgl_purchase_request_selesai,
    dataFilter?.filter?.tgl_pemakaian_mulai,
    dataFilter?.filter?.tgl_pemakaian_selesai,
    dataFilter?.filter?.keperluan,
    dataFilter?.filter?.nama_item,
  ]);

  const PageContent = () => {
    const ItemBarangCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);
  
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data?.map((brg, index) => {
              if (isReadMoreClick) {
                return <li key={index} index={index}>{`${brg?.nama_item} ${brg?.qty ? brg?.qty : ""} ${brg?.nama_satuan ? brg?.nama_satuan : ""}`?? "-"}</li>;
              } else {
                return index <= 1 && <li key={index} index={index}>{`${brg?.nama_item} ${brg?.qty ? brg?.qty : ""} ${brg?.nama_satuan ? brg?.nama_satuan : ""}`?? "-"}</li>;
              }
            })}
          </ul>
          {data.length > 2 ? (
            <div
              className="text-primary"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                // fontWeight: "bold",
              }}
              onClick={() => { setisReadMoreClick((prev) => !prev)}}>
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          ): ("")}
        </>
      );
    }

    const DataTable = () => (
      <>
        <div className="mt-3" style={{ fontSize: "14px" }}><b>List Data Purchase Request Yang Siap Dibuatkan Seleksi Vendor</b></div>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Purchase Request</ThFixed>
              <ThFixed>Keperluan</ThFixed>
              <ThFixed>Tgl. Pemakaian</ThFixed>
              <Th>Nama Item</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataPurchaseRequest.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_purchase_request ? DateConvert(new Date(val.tgl_purchase_request)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_purchase_request ?? "-"}</div>
                </TdFixed>
                <TdFixed><div className="text-left">{val.keperluan ?? "-"}</div></TdFixed>
                <TdFixed><div className="text-left">{val.tgl_pemakaian ? DateConvert(new Date(val.tgl_pemakaian)).defaultDMY : "-"}</div></TdFixed>
                <Td><ItemBarangCollapse data={val?.barang_jadi} /></Td>
                <Td>
                  <div className="d-flex justify-content-center">
                    <ReadButton
                      onClick={() => {
                        setProcessedData(val);
                        setModalConfig({ show: true, type: "detail" });
                      }}
                    />
                    <ActionButton
                      size="sm"
                      className="m-1 text-nowrap"
                      variant="success"
                      text={<AiOutlinePlus />}
                      onClick={() => history.push("/transaksi/seleksi-vendor/tambah/" + val.id_purchase_request)}
                    />
                  </div>
                </Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1}
          dataPage={dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
            ? dataFilter?.pagination?.dataCount
            : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              }
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              }
            })
          }
        />
      </>
    );
  };

  const PageModal = () => {
    const [modalData, setModalData] = useState({});
    const [isModalLoading, setIsModalLoading] = useState(true);
    const [isModalFetchingFailed, setIsModalFetchingFailed] = useState(false);

    const getInitialModalData = () => {
      SeleksiVendorApi.getSingle({ id_purchase_request: processedData.id_purchase_request })
        .then(({ data }) => {
          setModalData(data.data);
          setIsModalFetchingFailed(false);
        })
        .catch(() => {
          setIsModalFetchingFailed(true);
        })
        .finally(() => setIsModalLoading(false));
    };

    useEffect(() => {
      getInitialModalData();

      return () => {
        setIsModalLoading(true);
        setIsModalFetchingFailed(false);
      };
    }, []);

    const ModalTitle = () =>
      modalConfig.type.toLowerCase() === "detail" ? (
        <span className="text-primary">Detail Seleksi Vendor</span>
      ) : modalConfig.type.toLowerCase() === "update" ? (
        <span className="text-success">Ubah Seleksi Vendor</span>
      ) : modalConfig.type.toLowerCase() === "delete" ? (
        <span className="text-danger">Hapus Seleksi Vendor</span>
      ) : (
        <span className="text-primary">Tambah Seleksi Vendor</span>
      );

    const ModalDetail = () => {
      const ModalDetailInfo = () => {
        const ModalInfoItem = ({ title, value }) => (
          <tr>
            <td>
              <small>{title}</small>
            </td>
            <td className="pl-3 pr-1">
              <small>:</small>
            </td>
            <td>
              <small>{value}</small>
            </td>
          </tr>
        );

        return (
          <Row className="mb-3">
            <Col>
              <table>
                <tbody>
                  <ModalInfoItem
                    title="Tgl. Purchase Request"
                    value={
                      modalData.tgl_purchase_request
                        ? DateConvert(new Date(modalData.tgl_purchase_request)).detail
                        : "-"
                    }
                  />
                  <ModalInfoItem
                    title="Tgl. Pemakaian"
                    value={
                      modalData.tgl_pemakaian
                        ? DateConvert(new Date(modalData.tgl_pemakaian)).detail
                        : "-"
                    }
                  />
                </tbody>
              </table>
            </Col>
            <Col>
              <table>
                <tbody>
                  <ModalInfoItem
                    title="No. Purchase Request"
                    value={modalData.no_purchase_request ? modalData.no_purchase_request : "-"}
                  />
                  <ModalInfoItem
                    title="Keperluan"
                    value={modalData.keperluan ? modalData.keperluan : "-"}
                  />
                </tbody>
              </table>
            </Col>
          </Row>
        );
      };

      const ModalDetailTable = () => {
        return (
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Kode Item</ThFixed>
                <Th>Nama Item</Th>
                <Th>Satuan</Th>
                <Th>Qty</Th>
              </Tr>
            </THead>
            <TBody>
              {modalData.pr_detail.map((val, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <TdFixed>{val.kode_item}</TdFixed>
                  <Td>
                    {/* {val.dimensi === "3"
                      ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                      : val.dimensi === "2"
                      ? `${val.nama_item} (${val.pj} X ${val.lb})`
                      : val.dimensi === "1"
                      ? `${val.nama_item} (${val.pj})`
                      : val.dimensi === "0"
                      ? val.nama_item
                      : ""} */}
                    {val.nama_item}
                  </Td>
                  <Td>{val.satuan_beli}</Td>
                  <Td textRight>{val.qty_pr}</Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        );
      };

      return (
        <Modal.Body>
          {isModalLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isModalFetchingFailed ? (
            <DataStatus text="Data gagal dimuat" />
          ) : (
            <>
              <ModalDetailInfo />
              {modalData.pr_detail ? (
                modalData.pr_detail.length > 0 ? (
                  <ModalDetailTable />
                ) : (
                  <DataStatus text="Tidak ada item" />
                )
              ) : (
                <DataStatus text="Tidak ada item" />
              )}
            </>
          )}
        </Modal.Body>
      );
    };

    return (
      <Modal
        size="xl"
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <ModalTitle />
        </Modal.Header>
        {modalConfig.type.toLowerCase() === "detail" && <ModalDetail />}
      </Modal>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchConfig({
                    ...searchConfig,
                    key: e.target.value,
                  })
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setFilterConfig({ ...filterConfig, show: true, type: "filter" })}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push("/transaksi/seleksi-vendor", { ...location?.state, transfer: dataFilter })} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {/* content */}
      {isPageLoading 
        ? (<DataStatus loading={true} text="Memuat data . . ." />) 
        : dataPurchaseRequest 
          ? (dataPurchaseRequest.length > 0 
            ? (<PageContent />) 
            : (<DataStatus text="Tidak ada data" />)) 
          : (<DataStatus text="Data gagal dimuat" />)
      }
      <PageModal />
      {filterConfig.show && filterConfig.type === "filter" && (
        <ModalFilter
          dropdown={dropdownItem}
          modalConfig={filterConfig}
          setModalConfig={setFilterConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default ListPurchaseRequestSeleksiVendor;